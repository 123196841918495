import React from 'react';
// context and data
import useTrackPageShown from 'src/viewLayer/components/hooks/useTrackPageShown';
// view components
import EmailInput from 'src/viewLayer/components/shared/Inputs/EmailInput';
import CardLayout from 'src/viewLayer/components/pages/CardLayout';
import BackButton from 'src/viewLayer/components/pages/BackButton';
import SignupLink from 'src/viewLayer/components/shared/HintLinks/SignupLink';
import TrackableButton from 'src/viewLayer/components/shared/TrackableButton';
import HandCardImage from 'src/viewLayer/components/pages/Images/HandCard.svg';
import ScribbleImage from 'src/viewLayer/components/pages/Images/Scribble.svg';
import EmailImage from './Email.svg';
import ZendeskWidget from './ZendeskWidget';
import { t } from 'src/viewLayer/helpers/translations';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { isRebranded } from 'src/viewLayer/components/DesignSystem';
import {
  ActionsContainer,
  FormContainer,
  InputFrame,
  LoginEmailPageContainer
} from './index.styled';

export default function LoginEmailPage({
    appType,
    path,
    email,
    errorKey,
    isFocused,
    inProgress,
    onSubmit,
    onChange,
    onReset
  }) {
  useTrackPageShown({ appType,  pageType: path });
  const { queryParams, locale } = useRouteParamsContext();
  // if the user is viewing the page in German and came from a link to cancel
  const userWantsToCancelSubscription = locale === 'de' && queryParams.get('cancel');
  const pageTitleKey = userWantsToCancelSubscription ? 'email.cancel.title' : 'email.title';

  return (
    <LoginEmailPageContainer align="leading" alignItems="center">
      <CardLayout
        appType={appType}
        title={ t(pageTitleKey) }
        Image={ EmailImage }
        BackgroundItems={ !isRebranded && [HandCardImage, ScribbleImage] }
        hideTermsAndConditions={ userWantsToCancelSubscription }
      >
        { !userWantsToCancelSubscription && <SignupLink /> }
        <BackButton trackingId="backbutton-webview-dismiss" path={ path } />
        <FormContainer>
          <form onSubmit={ onSubmit }>
            <InputFrame>
              <EmailInput
                clearValue={ onReset }
                errorKey={ errorKey }
                isFocused={ isFocused }
                disabled={ inProgress }
                onChangeHandler={ onChange }
                value={ email }
              />
            </InputFrame>
          </form>
          <ActionsContainer>
            <TrackableButton
              id="uat-continue-button"
              fullWidth={true}
              disabled={ email.length === 0 || inProgress }
              inProgress={ inProgress }
              onClick={ onSubmit }
              title="Continue button"
              trackingId="email_continue"
              variant="primary"
            >
              { t('email.button.continue') }
            </TrackableButton>
          </ActionsContainer>
        </FormContainer>
      </CardLayout>
      <ZendeskWidget />
    </LoginEmailPageContainer>
  );
};
