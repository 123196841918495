import React from 'react';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';
import { useEmailPagePath } from 'src/viewLayer/navigation';
import { onBackButtonClicked } from 'src/viewLayer/helpers/safeNativeReporter';
import TrackableLink from 'src/viewLayer/components/shared/TrackableLink';
import { IconBackArrow } from 'src/viewLayer/components/DesignSystem';
import { BackButtonContainer } from './index.styled';

export default (props) => {
  const { devicePath } = useAppConfigContext();

  const isNativeMobileAndEmailView = devicePath === 'login-mobile' && props.path === 'email';
  const isNativeMobileAndPasswordView = devicePath === 'login-mobile' && props.path === 'password';
  const isNativeMobileAndBusinessView = devicePath === 'login-mobile' && props.path === 'business';

  // Back button in email and business view native
  if (isNativeMobileAndEmailView || isNativeMobileAndBusinessView) {
    const trackingId = isNativeMobileAndEmailView ? 'email_back_button_webview' : 'b2b_sso_back_button_webview';
    return (
      <BackButtonContainer>
        <TrackableLink
          {...props}
          data-testid="back-button-webview"
          href="#"
          onClick={onBackButtonClicked}
          trackingId={trackingId}
          icon={<IconBackArrow/>}
          aria-label="email_login.btn.back"
        />
      </BackButtonContainer>
    );
  }

  // Back button in password view native
  if (isNativeMobileAndPasswordView) {
    return (
      <BackButtonContainer>
        <TrackableLink
          {...props}
          className="BackButton"
          data-testid="back-button-default"
          href={useEmailPagePath()}
          icon={<IconBackArrow/>}
          aria-label="passsword.btn.back"
        />
      </BackButtonContainer>
    );
  }

  return null;
};
