import React from 'react';
// Hooks
import usePerformanceTracker from 'src/viewLayer/components/hooks/usePerformanceTracker';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';

// Components
import NavBar from './NavBar';
import BusinessErrorPage from 'src/viewLayer/components/pages/BusinessErrorPage';
import Footer from 'src/viewLayer/components/pages/CardLayout/Footer';
import { FooterContainer } from './index.styled';

const WebBusinessErrorPage = ({ appType }) => (
  <>
    <NavBar/>
    <BusinessErrorPage appType={ appType } />
    <FooterContainer>
      <Footer color="transparent" />
    </FooterContainer>
  </>
);

const NativeMobileBusinessErrorPage = ({ appType }) => (
  <BusinessErrorPage appType={ appType } />
);

export default function BusinessErrorPages(props) {
  usePerformanceTracker();
  const { devicePath } = useAppConfigContext();

  return devicePath === 'login' ?
    <WebBusinessErrorPage { ...props } /> :
    <NativeMobileBusinessErrorPage { ...props } />;
};
