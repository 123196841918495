import { isRebranded } from 'src/viewLayer/components/DesignSystem';

const rebrandedThemeDefaultProps = {
  theme: {
    color: {
      neutral: {
        surfaceDefault: 'none'
      }
    }
  }
};
const defaultThemeDefaultProps = {
  theme: {
    color: {
      reference: {
        grey: {
          grey99: 'none'
        }
      },
      surface: {
        container: {
          background: 'none'
        },
        layer: {
          background: 'none'
        }
      }
    }
  }
};

export const getThemeDefaultProps = () =>
  isRebranded ? rebrandedThemeDefaultProps : defaultThemeDefaultProps;
