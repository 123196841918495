import { useEffect } from 'react';
import { trackPageShown } from 'src/dataLayer/remoteData/babbelEventStream';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';

export default ({ appType, pageType }) => {
  const { locale } = useRouteParamsContext();
  useEffect(() => trackPageShown({ appType, locale, pageType }), [
    appType, pageType, locale
  ]);
};
