import React from 'react';

import { BabbelThemeProvider } from 'src/viewLayer/components/DesignSystem';

// The following wrappers are to disable dark mode for web until it has been implemented in the rest of the company
export const BabbelThemeProviderWeb = ({ children }) =>
  <BabbelThemeProvider defaultMode="light">{children}</BabbelThemeProvider>;

export const BabbelThemeProviderMobile = ({ children }) =>
  <BabbelThemeProvider defaultMode="system">{children}</BabbelThemeProvider>;
