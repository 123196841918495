import declareContext from './declareContext';
import { getReturnToUrl, getValidQueryParams } from 'src/dataLayer/localData/queryParams';

const extractProps = ({ locale, environment, queryParams }) => {
  const validQueryParams = getValidQueryParams(queryParams);

  return {
    queryParams: validQueryParams,
    redirectTo: getReturnToUrl(queryParams),
    locale,
    environment
  };
};

const RouteParamsContext = declareContext(extractProps);

export const useRouteParamsContext = RouteParamsContext.useContext;
export const RouteParamsProvider = RouteParamsContext.Provider;
