import styled from 'styled-components';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import zIndex from 'src/viewLayer/styling/mixins/z-index';
import { isRebranded, getColoursFromTheme, Box, Stack } from 'src/viewLayer/components/DesignSystem';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

export const CardLayout = styled(Stack)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
  width: 100%;

  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  }
`;

export const CardContainer = styled(Box)`
  height: ${ !isRebranded && '100%'};
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: ${zIndex.card};

  @media only screen and ${devices.fromxxsmall} {
    max-width: ${({ webview }) => webview && '23rem'};
  }

  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
    border-radius: 0px ${tokens.size.radius.large} ${tokens.size.radius.large} ${tokens.size.radius.large};
    margin: 2rem auto 1.25rem auto;
    width: 32.75rem;
  }

  @media only screen and ${devices.frommedium} {
    width: 35.625rem;
  }
`;

export const Card = styled(Stack)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.loginContainer.backgroundColor};
  margin: 0 auto;
  position: relative;
  z-index: ${zIndex.foreground};

  @media only screen and ${devices.fromsmall} {
    border-radius: 0px ${tokens.size.radius.large} ${tokens.size.radius.large} ${tokens.size.radius.large};
    box-shadow: ${ !isRebranded && `0 0 50px ${tokens.boxShadow.active.card}`};
  }
`;

export const BackgroundItemsContainer = styled(Box)`
  & > * {
    display: none;
    position: absolute;

    @media only screen and ${devices.fromsmall} {
      display: initial;
    }
  }
`;
