import React from 'react';
import {
  Routes,
  Route,
  Outlet,
  useParams,
  useSearchParams
} from 'react-router-dom';
// Providers
import { RouteParamsProvider } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { AppConfigProvider } from 'src/viewLayer/components/contexts/AppConfigContext';
// Pages Component
import LoginPages from './LoginPages';
import NativeMobileLoginPages from './NativeMobileLoginPages';
import BusinessPages from './BusinessPages';
import SuccessCallbackPage from './Authenticated/SuccessCallbackPage';
import BusinessErrorPages from './BusinessErrorPages';
// Redirects
import DefaultLoginRedirect from 'src/viewLayer/components/redirects/DefaultLoginRedirect';
import AuthenticatedRedirect from 'src/viewLayer/components/redirects/AuthenticatedRedirect';
import UnauthorizedBusinessRedirect from 'src/viewLayer/components/redirects/UnauthorizedBusinessRedirect';
// Check for logged-in/logged-out user
import { UUID } from 'src/dataLayer/env';
import { AppComponentWithBackground } from './index.styled';
import { BabbelThemeProviderWeb, BabbelThemeProviderMobile } from './ThemeProviders';
import { BabbelFonts } from 'src/viewLayer/components/DesignSystem';
import './index.scss';

const RouterParamsWrapper = (props) => {
  let { locale, environment } = useParams();
  let [searchParams] = useSearchParams();
  return (
    <RouteParamsProvider
      locale={ locale }
      environment={ environment }
      queryParams={ searchParams.toString() }
      { ...props }
    >
      <Outlet />
    </RouteParamsProvider>
  );
};
const EmailLoginRouter = () => (
  <BabbelThemeProviderWeb>
    <AppConfigProvider devicePath="login">
      <LoginPages appType="login" />
    </AppConfigProvider>
  </BabbelThemeProviderWeb>
);
const BusinessRouter = () => (
  <BabbelThemeProviderWeb>
    <AppConfigProvider devicePath="login">
      <Routes>
        <Route path="/" element={ <BusinessPages appType="login" /> } />
        <Route path="error" element={ <BusinessErrorPages appType="login" /> } />
        <Route path="*" element={ <UnauthorizedBusinessRedirect /> } />
      </Routes>
    </AppConfigProvider>
  </BabbelThemeProviderWeb>
);
const NativeMobileLoginRouter = () => (
  <BabbelThemeProviderMobile>
    <AppConfigProvider devicePath="login-mobile">
      <NativeMobileLoginPages appType="webview-login" />
    </AppConfigProvider>
  </BabbelThemeProviderMobile>
);
const NativeMobileBusinessRouter = () => (
  <BabbelThemeProviderMobile>
    <AppConfigProvider devicePath="login-mobile">
      <Routes>
        <Route path="/" element={ <BusinessPages appType="webview-login" /> } />
        <Route path="error" element={ <BusinessErrorPages appType="webview-login" /> } />
        <Route path="*" element={ <UnauthorizedBusinessRedirect /> } />
      </Routes>
    </AppConfigProvider>
  </BabbelThemeProviderMobile>
);
const UnauthenticatedApp = () => {
  // TODO: Remove appType and use the path from AppConfigProvider
  return (
    <AppComponentWithBackground data-testid="unauthenticated-app">
      <BabbelFonts/>
      <Routes>
        <Route path=":locale/:environment" element={ <RouterParamsWrapper /> }>
          <Route path="login/*" element={ <EmailLoginRouter /> } />
          <Route path="login/business/*" element={ <BusinessRouter /> } />
          <Route path="login-mobile/*" element={ <NativeMobileLoginRouter /> } />
          <Route path="login-mobile/business/*" element={ <NativeMobileBusinessRouter /> } />
          <Route path="*" element={ <DefaultLoginRedirect /> } />
          <Route path="" element={ <DefaultLoginRedirect /> } />
        </Route>
      </Routes>
    </AppComponentWithBackground>
  );
};
const AuthenticatedNativeMobileBusinessRouter = () => (
  <BabbelThemeProviderMobile>
    <AppConfigProvider devicePath="login-mobile">
      <Routes>
        <Route path="success" element={<SuccessCallbackPage appType="webview-login" />} />
        <Route path="*" element={<AuthenticatedRedirect />} />
      </Routes>
    </AppConfigProvider>
  </BabbelThemeProviderMobile>
);
const AuthenticatedApp = () => {
  // TODO: Remove appType and use the path from AppConfigProvider
  // Authenticated App is for B2B SSO via Native WebView
  // any route that does not match this condition,
  // redirects to return_to (when available) or dashboard (when no return_to)
  // Valid routes are: login-mobile/business/error and login-mobile/business/success
  return (
    <AppComponentWithBackground data-testid="authenticated-app">
      <BabbelFonts/>
      <Routes>
        <Route path=":locale/:environment" element={ <RouterParamsWrapper />}>
          <Route path="login-mobile/business/*" element={ <AuthenticatedNativeMobileBusinessRouter /> } />
          <Route path="*" element={ <AuthenticatedRedirect /> } />
          <Route path="" element={ <AuthenticatedRedirect /> } />
        </Route>
      </Routes>
    </AppComponentWithBackground>
  );
};
export default function App() {
  return (UUID ? <AuthenticatedApp /> : <UnauthenticatedApp />);
}
