import React from 'react';
import TrackableButton from 'src/viewLayer/components/shared/TrackableButton';
import { t } from 'src/viewLayer/helpers/translations';
import { useBusinessPagePath } from '../../../navigation';

export default () => {
  return (
    <TrackableButton
      id="uat-business-button"
      color="secondary"
      trackingId="login_b2b_sso"
      href={ useBusinessPagePath() }
    >
      { t('login_options.button.b2b.company_email') }
    </TrackableButton>
  );
};
