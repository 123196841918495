export const postMessage = (message) => {
  if (!message) console.error('Missing postMessage string parameter');

  try {
    window.webkit.messageHandlers.iosAuthTokenListener.postMessage(message);
  } catch (error) {
    console.error('iOS postMessage: ', error);
  }
  try {
    window.androidListener.postMessage(message);
  } catch (error) {
    console.error('Android postMessage: ', error);
  }
};

export const onBackButtonClicked = () => {
  try {
    window.webkit.messageHandlers.iosUIListener.postMessage('onBackButtonClicked');
  } catch (error) {
    console.error('iOS onBackButtonClicked: ', error);
  }
  try {
    window.androidListener.onBackButtonClicked();
  } catch (error) {
    console.error('Android onBackButtonClicked: ', error);
  }
};

export const onForgotPasswordClicked = () => {
  try {
    window.webkit.messageHandlers.iosUIListener.postMessage('onForgotPasswordClicked');
  } catch (error) {
    console.error('iOS onForgotPasswordClicked: ', error);
  }
  try {
    window.androidListener.onForgotPasswordClicked();
  } catch (error) {
    console.error('Android onForgotPasswordClicked: ', error);
  }
};
