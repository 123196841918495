import React from 'react';
import { Link } from 'src/viewLayer/components/DesignSystem';

import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { trackGuiInteracted } from 'src/dataLayer/remoteData/babbelEventStream';

export default ({
  trackingId,
  href,
  ...props
}) => {
  const { locale, learnLanguageCode } = useRouteParamsContext();

  const trackClick = () => {
    if (!props.disabled) {
      trackGuiInteracted({
        locale,
        learnLanguageCode,
        guiElementName: trackingId
      });
    }
  };

  const handleClick = () => {
    trackClick();
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Link
      {...props}
      // if there is no href passed it needs to be null to avoid
      // redirecting the user when we should only dismiss the webview
      href={href || null}
      onClick={handleClick}
    />
  );
};
