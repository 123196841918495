import React, { useEffect } from 'react';
import { getReturnToUrl } from 'src/dataLayer/localData/queryParams';
import { Layout, Spinner } from 'src/viewLayer/components/DesignSystem';

/*
 * Used as the default redirect handler for authenticated
 * pages. Does not create a history item.
 */

export default function AuthenticatedRedirect() {
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      window.location.replace(getReturnToUrl(window.location.search, false));
    }, 2000);

    return () => clearTimeout(timeoutRef);
  }, []);

  return (
    <Layout align="center" padding={[150, 0]}>
      <Spinner data-testid="babbel-spinner" size={42} />
    </Layout>
  );
};
