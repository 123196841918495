import useStateWithInterface from 'src/viewLayer/components/hooks/useStateWithInterface';

const INITIAL_STATE = {
  data: null,
  inProgress: false,
  errors: []
};

const stateInterface = {
  onAttempt: () => ({
    errors: INITIAL_STATE.errors,
    inProgress: true
  }),
  onSuccess: (data) => ({
    ...INITIAL_STATE,
    data
  }),
  onFailure: (errors) => ({
    ...INITIAL_STATE,
    errors
  })
};

export default () =>
  useStateWithInterface(INITIAL_STATE, stateInterface);
