import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEmailPagePath, usePasswordPagePath } from '../../navigation';
/*
 * Redirects to /login/email, used as the default handler for login
 * pages. Does not create a history item.
 */

export default function DefaultLoginRedirect(props) {
  const emailPagePath = useEmailPagePath();
  const passwordPagePath = usePasswordPagePath();
  const redirectPath = props.localStorageEmail ? passwordPagePath : emailPagePath;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(redirectPath, { replace: true });
  }, [redirectPath, navigate]);

  return null;
};
