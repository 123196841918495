import React from 'react';
import {
  IconAppleColored as InternalIconAppleColored,
  IconBackArrow as InternalIconBackArrow,
  IconCloseSmall as InternalIconCloseSmall,
  IconGoogleColored as InternalIconGoogleColored,
  IconFacebookColored as InternalIconFacebookColored,
  IconGlobe as InternalIconGlobe,
  IconHide as InternalIconHide,
  IconLock as InternalIconLock,
  IconMail as InternalIconMail,
  IconShow as InternalIconShow
} from '@lessonnine/design-system.lib';

import {
  IconAppleColored as InternalIconAppleColoredRebranded,
  IconCaretLeftBold as InternalIconBackArrowRebranded,
  IconClose as InternalIconCloseRebranded,
  IconGoogleColored as InternalIconGoogleColoredRebranded,
  IconFacebookColored as InternalIconFacebookColoredRebranded,
  IconGlobe as InternalIconGlobeRebranded,
  IconEyeClosed as InternalIconHideRebranded,
  IconLock as InternalIconLockRebranded,
  IconEnvelopeSimple as InternalIconMailRebranded,
  IconEye as InternalIconShowRebranded,
  IconXCircle as InternalIconXCircleRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const IconsMap = {
  [DS_VERSIONS.default]: {
    IconAppleColored: InternalIconAppleColored,
    IconBackArrow: InternalIconBackArrow,
    IconClose: InternalIconCloseSmall,
    IconGoogleColored: InternalIconGoogleColored,
    IconFacebookColored: InternalIconFacebookColored,
    IconGlobe: InternalIconGlobe,
    IconHide: InternalIconHide,
    IconLock: InternalIconLock,
    IconMail: InternalIconMail,
    IconShow: InternalIconShow,
    // we need to use a placeholder icon for IconXCircle to avoid errors in the non-rebranded version
    IconXCircle: InternalIconShow
  },
  [DS_VERSIONS.rebranded]: {
    IconAppleColored: ({ props }) => <InternalIconAppleColoredRebranded size="large" {...props} />,
    IconBackArrow: InternalIconBackArrowRebranded,
    IconClose: InternalIconCloseRebranded,
    IconGoogleColored: ({ props }) => <InternalIconGoogleColoredRebranded size="large" {...props} />,
    IconFacebookColored: ({ props }) => <InternalIconFacebookColoredRebranded size="large" {...props} />,
    IconGlobe: InternalIconGlobeRebranded,
    IconHide: InternalIconHideRebranded,
    IconLock: InternalIconLockRebranded,
    IconMail: InternalIconMailRebranded,
    IconShow: InternalIconShowRebranded,
    IconXCircle: InternalIconXCircleRebranded
  }
};

export default IconsMap[CURRENT_DS_VERSION];
