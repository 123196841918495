import { LOCALE_TO_ZENDESK_MAPPING } from 'src/dataLayer/localData/languageCodes';
import { LOCALE } from 'src/dataLayer/env';

export const ZENDESK_DEPARTMENTS = {
  de: 'German',
  en: 'English_US',
  en_GB: 'English_GB',
  fr: 'French',
  it: 'Italian',
  pl: 'Polish',
  pt: 'Portuguese',
  es: 'Spanish',
  sv: 'Swedish'
};

const ZENDESK_DEFAULT_LOCALE = 'en-gb';

export const getZendeskLocale = () =>
  LOCALE_TO_ZENDESK_MAPPING[LOCALE] || ZENDESK_DEFAULT_LOCALE;
