import { reportAPIFailure } from 'src/dataLayer/remoteData/rollbar';

const EMPTY_ERROR = '';

export const getErrorText = (triggeredError, errorCode) =>
  triggeredError[errorCode]?.key || triggeredError.key || EMPTY_ERROR;

export const successHandler = async (response) => {
  if (response.ok) {
    return await response.json();
  }

  const { error } = await response.json();

  // we need to destructure error attribute otherwise
  // it will have { error: error {} } structure
  throw {
    ...error,
    status: response.status
  };
};

export const failureHandler = (error, { payload, errorKeys }) => {
  reportAPIFailure(error, payload);
  const triggeredError = errorKeys[error.status] || errorKeys.default;
  throw getErrorText(triggeredError, error.code);
};
