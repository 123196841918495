import React from 'react';
import { Button } from 'src/viewLayer/components/DesignSystem';

import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { trackGuiInteracted } from 'src/dataLayer/remoteData/babbelEventStream';

export default ({
  children,
  color = 'primary',
  dataTestid,
  disabled,
  fullWidth = true,
  href,
  buttonIcon,
  id,
  inProgress,
  trackingId,
  onClick = () => {},
  minWidth = 152,
  title
}) => {
  const { locale, learnLanguageCode } = useRouteParamsContext();

  const trackClick = () => {
    if (inProgress || !disabled) {
      trackGuiInteracted({
        locale,
        learnLanguageCode,
        guiElementName: trackingId
      });
    }
  };

  const handleClick = (event) => {
    trackClick();
    onClick(event);
  };

  return (
    <Button
      as={href ? 'a' : 'button'}
      color={buttonIcon ? 'secondary' : color}
      data-testid={dataTestid}
      disabled={disabled}
      fullWidth={fullWidth}
      href={href}
      icon={buttonIcon}
      id={id}
      loading={inProgress}
      onClick={(event) => handleClick(event)}
      minWidth={minWidth}
      title={title}
    >
      { children }
    </Button>
  );
};
