import {
  ZENDESK_DEPARTMENTS,
  getZendeskLocale
} from 'src/dataLayer/localData/zendeskConfig';
import { LOCALE } from 'src/dataLayer/env';

// eslint-disable-next-line max-len, quotes
const widgetCode = `window.zEmbed||function(e,t){var n,o,d,i,s,a=[],r=document.createElement("iframe");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,this.zendeskHost="babbel.zendesk.com",this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()}();`;

const webWidgetSettings = () => ({
  chat: {
    suppress: false,
    departments: {
      enabled: [], // If an empty array is passed, the department dropdown won't appear.
      select: ZENDESK_DEPARTMENTS[LOCALE]
    }
  },
  helpCenter: { suppress: true },
  talk: { suppress: true },
  answerBot: { suppress: true }
});

const load = () =>
  new Function(widgetCode)();

const setConfig = () => window.zESettings = { webWidget: webWidgetSettings() };

const setLocale = () =>
  window.zE('messenger:set', 'locale', getZendeskLocale(LOCALE));

export default () => {
  load();
  setConfig();
  setLocale();
};
