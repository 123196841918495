import React from 'react';

import { IconClose, IconGlobe, Stack, TextField, TextFieldButton } from 'src/viewLayer/components/DesignSystem';
import { t } from 'src/viewLayer/helpers/translations';

export default ({
  clearValue,
  disabled,
  errorKey,
  isFocused,
  label,
  onChangeHandler,
  value
}) => (
  <Stack className="InputFrame" gap={16}>
    <TextField
      color="lightened"
      actionButton={
        value ?
          <TextFieldButton
            onClick={ () => clearValue() }
            icon={ <IconClose /> }
            title={ 'Clear' }
          />
          : null
      }
      autoComplete="subdomain"
      autoFocus={ isFocused }
      additionalText={ errorKey && t(errorKey) }
      data-testid="identityprovider-input-field"
      disabled={ disabled }
      fullWidth={ true }
      icon={ <IconGlobe/> }
      id="uat-identityprovider-input-field"
      label={ label }
      onChange={ onChangeHandler }
      placeholder={ t('login.b2b.identity_provider_placeholder') }
      prefilledText=".babbel.com"
      state={ errorKey ? 'error' : 'default' }
      type="text"
      value={ value }
    />
  </Stack>
);

