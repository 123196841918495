import styled from 'styled-components';
import { getColoursFromTheme, Box, Stack } from 'src/viewLayer/components/DesignSystem';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import zIndex from 'src/viewLayer/styling/mixins/z-index';
import TrackableLink from 'src/viewLayer/components/shared/TrackableLink';

export const LoginPasswordPageContainer = styled.div`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
  height: 100%;
  min-height: calc(100% - 4rem);

  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  }
`;

export const FormContainer = styled(Stack)`
  margin: 0 auto;
  width: 100%;

  @media only screen and ${devices.fromxxsmall} {
    width: 20rem;
  }
  @media only screen and ${devices.fromxsmall} {
    width: 21rem;
  }
`;

export const InputFrame = styled(Box)`
  padding: 2.5rem 0 0 0;

  @include from-small {
    padding: 3rem 0 0 0;
  }
`;

export const ActionsContainer = styled(Box)`
  display: block;
  margin: 2rem auto 0;
  width: 100%;

  @media only screen and ${devices.fromsmall} {
    margin: 1.5rem auto 0;
  }

  @media only screen and ${devices.frommedium} {
    margin: 2rem auto 0;
  }
`;

export const KeysContainer = styled(Box)`
  left: -50%;
  top: -6.25rem;
  z-index: ${zIndex.back};
`;

export const BigLockContainer = styled(Box)`
  right: -33%;
  top: 17.188rem;
  z-index: ${zIndex.decorationBack};
`;

export const ForgotPasswordTrackableLink = styled(TrackableLink)`
  align-self: flex-end;
  margin-top: 0.625rem;
`;
