import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

const mediaQueries = {
  fromxxsmall: `(min-width: ${tokens.viewports.breakpoints.xxsmall})`,
  fromxsmall: `(min-width: ${tokens.viewports.breakpoints.xsmall})`,
  fromsmall: `(min-width: ${tokens.viewports.breakpoints.small})`,
  frommedium: `(min-width: ${tokens.viewports.breakpoints.medium})`,
  fromlarge: `(min-width: ${tokens.viewports.breakpoints.large})`
};

export default mediaQueries;
