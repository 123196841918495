/*
  we should be informed when no name or version is passed because
  tracker-babbel WILL FAIL SILENTLY in such cases. it will execute,
  but will not actually fire the event.

  we should also be informed when no locale or learn_language_alpha3 is passed because
  its part of the spec for the events we are using.
*/
import { RUNTIME_ENVIRONMENT } from 'src/dataLayer/env';
import { reportValidationError } from '../rollbar';

// Check that version is a numeric value
const validateVersion = (version) => version / 1 === version;

export const handleInvalidEventProps = (eventProps) => {
  if (RUNTIME_ENVIRONMENT !== 'production') {
    console.error(`
      Attempted to emit an event to Babbel Event Stream, but was either missing a required or contained an invalid prop:
        ${ JSON.stringify(eventProps) }
    `);
  }

  const { name, ...payload } = eventProps;

  reportValidationError('validationError', name, payload);
};

const hasValidEventProps = ({
  name,
  version,
  locale
}) => {
  return Boolean(
    name &&
    validateVersion(version) &&
    locale
  );
};

export const validateEventProps = (eventProps) => {
  if (!hasValidEventProps(eventProps)) handleInvalidEventProps(eventProps);
};
