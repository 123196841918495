import React from 'react';
import { isRebranded, Stack, Text } from 'src/viewLayer/components/DesignSystem';
import { ResponsiveHeader } from 'src/viewLayer/components/shared/ResponsiveHeader';

import { ImageContainer } from './index.styled';

export default ({ title, subtitle, Image }) => {
  const largeSizeHeader = isRebranded ? 'h1' : 'h2';

  return (
    <Stack
      className="HeaderComponent"
      alignItems="center"
      gap={ 16 }
      padding={[0, 0, 24, 0]}
    >
      { Image && !isRebranded
        && (<ImageContainer className="Header-image"><Image /></ImageContainer>)
      }
      {title &&
        <ResponsiveHeader largeSize={largeSizeHeader}>{title}</ResponsiveHeader>
      }
      {subtitle && (<Text>{subtitle}</Text>)}
    </Stack>
  );
};
