const zIndex = {
  modal: 200,
  toolbars: 150,
  foreground: 100,
  decorationFront: 20,
  decorationBack: 10,
  back: -1,
  card: 30
};

export default zIndex;
