import React from 'react';
// Hooks
import usePerformanceTracker from 'src/viewLayer/components/hooks/usePerformanceTracker';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';

// Components
import NavBar from './NavBar';
import LoginBusinessPage from 'src/viewLayer/components/pages/LoginBusinessPage';
import Footer from 'src/viewLayer/components/pages/CardLayout/Footer';
import { FooterContainer, LoginBusinessPageContainer } from './index.styled';

const WebBusinessPage = ({ appType }) => (
  <LoginBusinessPageContainer>
    <NavBar/>
    <LoginBusinessPage appType={ appType }>
      <FooterContainer>
        <Footer color="transparent" />
      </FooterContainer>
    </LoginBusinessPage>
  </LoginBusinessPageContainer>
);

const NativeMobileBusinessPage = ({ appType }) => (
  <LoginBusinessPage appType={ appType } />
);

export default function BusinessPages(props) {
  usePerformanceTracker();
  const { devicePath } = useAppConfigContext();

  return devicePath === 'login' ?
    <WebBusinessPage { ...props } /> :
    <NativeMobileBusinessPage { ...props } />;
};
