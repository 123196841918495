import React from 'react';
import { IconAppleColored, IconFacebookColored, IconGoogleColored } from 'src/viewLayer/components/DesignSystem';

export default (icon) => {
  switch (icon) {
    case 'facebook':
      return <IconFacebookColored />;
    case 'google':
      return <IconGoogleColored />;
    case 'apple':
      return <IconAppleColored />;
    default:
      return null;
  }
};
