import React, { useEffect, useState } from 'react';
import { Badge, Link } from 'src/viewLayer/components/DesignSystem';
import { Box } from 'src/viewLayer/components/DesignSystem';
import { resetCaptcha } from './helpers';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { t } from 'src/viewLayer/helpers/translations';
import {
  CAPTCHA_ERROR_KEY,
  CAPTCHA_TARGET_ID
} from 'src/dataLayer/localData/captcha';
import { loadCaptcha } from 'src/viewLayer/components/shared/CaptchaFeature/loadCaptcha';
import { executeCaptcha, extractCaptchaKey, isInvisible } from 'src/viewLayer/components/shared/CaptchaFeature/helpers';
import { CaptchaContainer } from './index.styled';

const reloadCaptcha = (sessionCode, provider, setStoredSessionCode) => {
  resetCaptcha(provider);
  setStoredSessionCode(sessionCode);
};

const CaptchaWidget = ({
  sessionCode,
  setCaptchaResponseToken,
  captchaConfig,
  passwordErrorKey,
  captchaResponseToken
}) => {
  const { locale } = useRouteParamsContext();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [captchaWidgetID, setCaptchaWidgetID] = useState(undefined);
  const [storedSessionCode, setStoredSessionCode] = useState(undefined);
  const { provider } = captchaConfig;

  useEffect(() => {
    const loadCaptchaArgs = {
      sessionCode,
      locale,
      showErrorMessage,
      setShowErrorMessage,
      setCaptchaResponseToken,
      captchaConfig,
      setCaptchaWidgetID,
      captchaWidgetID,
      setStoredSessionCode
    };

    // If the session has changed, reset the widget
    // This block must run after rendering the component, to stop captcha trying to render before the container is there
    (captchaWidgetID === undefined || sessionCode === storedSessionCode)
      ? loadCaptcha(loadCaptchaArgs)
      : reloadCaptcha(sessionCode, provider, setStoredSessionCode);
  }, [
    captchaWidgetID,
    sessionCode,
    locale,
    showErrorMessage,
    setShowErrorMessage,
    setCaptchaResponseToken,
    captchaConfig,
    provider,
    setStoredSessionCode,
    storedSessionCode
  ]);

  // If there is an error with the password, we need to tell invisible captcha to execute again.
  // The visible (checkbox) captcha doesn't need to re-execute, since the user will have already interacted with it.
  useEffect(() => {
    if (passwordErrorKey === '') return;
    if (isInvisible(provider)) setCaptchaResponseToken(undefined);
  }, [passwordErrorKey, provider, setCaptchaResponseToken]);

  useEffect(() => {
    if (captchaWidgetID && !captchaResponseToken && isInvisible(provider)) executeCaptcha(provider, captchaWidgetID);
  }, [captchaResponseToken, captchaWidgetID, provider]);

  return (
    <Link color="highlight" size="xsmall">
      <Box id={ CAPTCHA_TARGET_ID } />
      { showErrorMessage && <Badge color="unavailable" title="captcha-error">{t(CAPTCHA_ERROR_KEY)}</Badge> }
    </Link>
  );
};

// Render an empty container if user must not submit a captcha (for UI spacing purposes).
export default (props) => {
  const userMustSubmitCaptcha = Boolean(extractCaptchaKey(props.captchaConfig));
  if (userMustSubmitCaptcha) {
    return (
      <CaptchaContainer align="center" invisible={ isInvisible(props.captchaConfig.provider) }>
        <CaptchaWidget {...props} />
      </CaptchaContainer>
    );
  }
  return null;
};
