import React from 'react';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { NavBarWrapper } from 'src/viewLayer/components/DesignSystem';
import { getIndexPageURL } from 'src/dataLayer/env';

export default () => {
  const { locale } = useRouteParamsContext();

  return (
    <NavBarWrapper logoUrl={ getIndexPageURL(locale) } />
  );
};
