import React, { useState } from 'react';
import { IconHide, IconLock, IconShow, Stack, TextField, TextFieldButton } from 'src/viewLayer/components/DesignSystem';
import { t } from 'src/viewLayer/helpers/translations';

export default ({
  value,
  onChangeHandler,
  errorKey,
  isFocused,
  disabled
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Stack className="InputFrame" gap={16}>
      <TextField
        color="lightened"
        actionButton={
          value ?
            <TextFieldButton
              onClick={() => setVisible((previous) => !previous)}
              icon={ visible ? <IconShow /> : <IconHide /> }
              title={ visible ? 'Hide' : 'Show' }
            />
          : null
        }
        autoComplete="new-password"
        autoFocus={ isFocused }
        additionalText={ errorKey && t(errorKey) }
        disabled={ disabled }
        fullWidth={ true }
        icon={ <IconLock/> }
        id="uat-password-input-field"
        onChange={ onChangeHandler }
        placeholder={ t('password.placeholder') }
        state={ errorKey ? 'error' : 'default' }
        type={ visible ? 'text' : 'password' }
        value={ value }
      />
    </Stack>
  );
};
