const coloursRebranding = (theme) =>({
  authContainer: {
    backgroundColor: theme.color.neutral.surfaceDefault,
    foregroundColor: theme.color.neutral.surfaceDefault,
    textColor: theme.color.neutral.onSurfaceDefault,
    loginContainer: {
      backgroundColor: 'transparent'
    }
  }
});

export default coloursRebranding;
