/* eslint-disable key-spacing */

const ENGLISH_LOCALE_BRITISH =  'en_GB';
const ENGLISH_LOCALE_DEV =      'en_dev';
const ENGLISH_LOCALE =          'en';
const FRENCH_LOCALE =           'fr';
const GERMAN_LOCALE =           'de';
const ITALIAN_LOCALE =          'it';
const SPANISH_LOCALE =          'es';
const POLISH_LOCALE =           'pl';
const PORTUGUESE_LOCALE =       'pt';
const SWEDISH_LOCALE =          'sv';
export const UKRAINIAN_LOCALE = 'uk';

const DEFAULT_SUBDOMAIN = 'www';

const LOCALE_TO_SUBDOMAIN_MAPPING = {
  [ENGLISH_LOCALE_BRITISH]: 'uk',
  [ENGLISH_LOCALE_DEV]:     DEFAULT_SUBDOMAIN,
  [ENGLISH_LOCALE]:         DEFAULT_SUBDOMAIN,
  [GERMAN_LOCALE]:          'de',
  [SPANISH_LOCALE]:         'es',
  [FRENCH_LOCALE]:          'fr',
  [ITALIAN_LOCALE]:         'it',
  [POLISH_LOCALE]:          'pl',
  [PORTUGUESE_LOCALE]:      'pt',
  [SWEDISH_LOCALE]:         'se',
  [UKRAINIAN_LOCALE]:       'ua'
};

export const LOCALE_TO_ZENDESK_MAPPING = {
  [ENGLISH_LOCALE_DEV]: 'en-gb',
  [ENGLISH_LOCALE_BRITISH]: 'en-gb',
  [ENGLISH_LOCALE]: 'en-us',
  [SWEDISH_LOCALE]: 'sv',
  [SPANISH_LOCALE]: 'es',
  [PORTUGUESE_LOCALE]: 'pt-br',
  [ITALIAN_LOCALE]: 'it',
  [POLISH_LOCALE]: 'pl',
  [FRENCH_LOCALE]: 'fr',
  [GERMAN_LOCALE]: 'de',
  [UKRAINIAN_LOCALE]: 'uk'
};

export const getSubdomainFromLocale = (locale) =>
  LOCALE_TO_SUBDOMAIN_MAPPING[locale] || DEFAULT_SUBDOMAIN;  // added www as a default

// https://developers.google.com/recaptcha/docs/language
const RECAPTCHA_LOCALE_EXCEPTIONS = {
  [ENGLISH_LOCALE_BRITISH]: 'en-GB',
  [ENGLISH_LOCALE_DEV]:     ENGLISH_LOCALE
};

// https://docs.hcaptcha.com/languages
const HCAPTCHA_LOCALE_EXCEPTIONS = {
  [ENGLISH_LOCALE_BRITISH]: 'en',
  [ENGLISH_LOCALE_DEV]:     ENGLISH_LOCALE
};

const CAPTCHA_LOCALE_EXCEPTIONS_MAPPING = {
  recaptcha: RECAPTCHA_LOCALE_EXCEPTIONS,
  hcaptcha: HCAPTCHA_LOCALE_EXCEPTIONS
};

export const getCaptchaLocale = (locale, provider) =>
  CAPTCHA_LOCALE_EXCEPTIONS_MAPPING[provider][locale] || locale;
