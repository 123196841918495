import {
  BabbelFonts as InternalBabbelFonts,
  Header as InternalHeader,
  Text as InternalText
} from '@lessonnine/design-system.lib';

import {
  BabbelFonts as InternalBabbelFontsRebranded,
  Text as InternalTextRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { Title as InternalHeaderRebranded } from './HeaderRebranded';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Typography = {
  [DS_VERSIONS.default]: {
    BabbelFonts: InternalBabbelFonts,
    Header: InternalHeader,
    Text: InternalText
  },
  [DS_VERSIONS.rebranded]: {
    BabbelFonts: InternalBabbelFontsRebranded,
    Header: InternalHeaderRebranded,
    Text: InternalTextRebranded
  }
};

export default Typography[CURRENT_DS_VERSION];
