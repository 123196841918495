import styled from 'styled-components';
import { Box } from 'src/viewLayer/components/DesignSystem';

export const FooterContainer = styled(Box)`
  border-top: none;
  margin: 0 auto;
  width: 100vw;
  z-index: 30;
  background-color: transparent;
`;
