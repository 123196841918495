/*
  Promise-ified loading of the captcha script
*/

import { getCaptchaLocale } from 'src/dataLayer/localData/languageCodes';
import {
  CAPTCHA_CALLBACK,
  CAPTCHA_MAX_LOAD_TIME,
  CAPTCHA_PROVIDER_CONFIG
} from 'src/dataLayer/localData/captcha';

let captchaScriptLoaded = false;

// locale can be fetched automatically by captcha from the browser, but to be consistent with our url which contains a
// locale we will set it explicitly
const getCaptchaScriptURL = (locale, provider) => {
  const captchaLocale = getCaptchaLocale(locale, provider);
  return `${ CAPTCHA_PROVIDER_CONFIG[provider]?.scriptURL }&hl=${captchaLocale}`;
};

const createCaptchaScriptTag = (captchaScriptURL) => {
  const captchaScriptTag = document.createElement('script');
  captchaScriptTag.setAttribute('src', captchaScriptURL);
  captchaScriptTag.setAttribute('async', true);
  captchaScriptTag.setAttribute('defer', true);
  document.querySelector('head').appendChild(captchaScriptTag);
};

// Will return the widget ID if render is successful
const renderCaptchaOnLoad = async ({ targetId, captchaKey, onSuccess, onError, onExpired, provider }) => {
  const captchaProviderMethod = CAPTCHA_PROVIDER_CONFIG[provider]?.windowMethod;
  const isHcaptcha = provider === 'hcaptcha';

  try {
    if (!captchaProviderMethod) throw new Error('captcha provider was not recognised');

    return await window[captchaProviderMethod].render(targetId, {
      sitekey: captchaKey,
      callback: onSuccess,
      'error-callback': onError,
      'expired-callback': onExpired,
      size: CAPTCHA_PROVIDER_CONFIG[provider]?.type,
      'chalexpired-callback': isHcaptcha ? onExpired : undefined,
      'close-callback': isHcaptcha ? onExpired : undefined
    });
  } catch (err) {
    onError(err);
  }
};

const addCaptchaScript = (args) =>
  new Promise((resolve, reject) => {
    window[CAPTCHA_CALLBACK] = async () => {
      captchaScriptLoaded = true;
      return resolve(await renderCaptchaOnLoad(args));
    };
    window.setTimeout(() => {
      return reject('The captcha script failed to load.');
    }, CAPTCHA_MAX_LOAD_TIME);
    const captchaScriptURL = getCaptchaScriptURL(args.locale, args.provider);
    createCaptchaScriptTag(captchaScriptURL);
  });

export default async (args) => {
  return captchaScriptLoaded
    ? await renderCaptchaOnLoad(args)
    : await addCaptchaScript(args).catch((err) => args.onError(err));
};
