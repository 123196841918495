const coloursDefault = (theme) => ({
  authContainer: {
    backgroundColor: theme.color.surface.container.background,
    foregroundColor: theme.color.surface.layer.background,
    textColor: theme.color.reference.grey.grey99,
    loginContainer: {
      backgroundColor: theme.color.surface.layer.background
    }
  }
});

export default coloursDefault;
