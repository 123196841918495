import {
  Button as InternalButton
} from '@lessonnine/design-system.lib';

import {
  Button as InternalButtonRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Button = {
  [DS_VERSIONS.default]: InternalButton,
  [DS_VERSIONS.rebranded]: InternalButtonRebranded
};

export default Button[CURRENT_DS_VERSION];
