import styled from 'styled-components';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import { isRebranded, getColoursFromTheme, Box, Stack } from 'src/viewLayer/components/DesignSystem';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';
import { getThemeDefaultProps } from 'src/viewLayer/styling/helpers';

export const CardLayout = styled(Box)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
  margin: 0 auto;
  max-width: 28.75rem;

  @media only screen and ${devices.fromxsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
    max-width: ${ isRebranded ? '28.75rem' : '35.625rem'};
  }

  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  }
`;

export const Card = styled(Box)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.loginContainer.backgroundColor};
  height: 100%;
  min-height: calc(100% - 4rem);
  position: relative;

  @media only screen and ${devices.fromsmall} {
    border-radius: 0 ${tokens.size.radius.large} ${tokens.size.radius.large} ${tokens.size.radius.large};
    box-shadow: ${!isRebranded && `0 0 50px ${tokens.boxShadow.active.card}`};
    margin: 2rem auto;
    width: ${ isRebranded ? '28.75rem' : '35.625rem'};
  }
`;

export const LoginBusinessPageWithBackground = styled.div`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
  height: max-content;
  min-height: 100%;
  position: absolute;
  width: 100%;
  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  }
`;

export const FormContainer = styled(Stack)`
  margin: auto 0;
  width: 100%;
`;

CardLayout.defaultProps = getThemeDefaultProps();
Card.defaultProps = getThemeDefaultProps();
LoginBusinessPageWithBackground.defaultProps = getThemeDefaultProps();
