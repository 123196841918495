import React from 'react';

import { IconClose, IconMail, Stack, TextField, TextFieldButton } from 'src/viewLayer/components/DesignSystem';
import { t } from 'src/viewLayer/helpers/translations';

export default ({
  clearValue,
  disabled,
  errorKey,
  isFocused,
  onChangeHandler,
  value
}) => (
  <Stack className="InputFrame" gap={16}>
    <TextField
      color="lightened"
      actionButton={
        value ?
          <TextFieldButton
            onClick={ () => clearValue() }
            icon={ <IconClose /> }
            title={ 'Clear' }
          />
        : null
      }
      autoComplete="email"
      autoFocus={ isFocused }
      additionalText={ errorKey && t(errorKey) }
      disabled={ disabled }
      fullWidth={ true }
      icon={ <IconMail /> }
      id="uat-email-input-field"
      onChange={ onChangeHandler }
      placeholder={ t('email.placeholder') }
      state={ errorKey ? 'error' : 'default' }
      type="email"
      value={ value }
    />
  </Stack>
);
