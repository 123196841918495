import { reportError } from './reporter';

export const reportUncaughtException = (message, url, lineNumber, columnNumber, error) =>
  reportError(message, {
    url,
    lineNumber,
    columnNumber,
    stack: error ? error.stack : undefined
  });

export const reportValidationError = (message, eventName, payload) =>
  reportError('event:validation:failed', {
    eventName,
    payload,
    error: {
      message
    }
  });

export const reportTrackerFailure = (error, eventName, payload) =>
  reportError('tracker:failed', {
    eventName,
    payload,
    error: {
      message: error.message || error,
      status: error.status,
      readyState: error.readyState
    }
  });

const reportUnsuccessfulRequest = ({ error, body }) =>
  reportError('api:call:failed', { error, body });

export const reportAPIFailure = (error, payload) => {
  // Status Code from Authentication API specification:
  // https://confluence.internal.babbel.com/x/7JqRCw

  // Error data structure
  // error: {
  //   status: (int) 4XX,
  //   code: (string) 'mock_error',
  //   title: (string) 'Mock Error',
  //   detail: (string) 'Error is error'
  // }

  /*
  * The request was made but no response was received,
  * `error.request` is either an instance of XMLHttpRequest
  * or `error` is a generic error
  */

 if (error.status >= 500) {
   return reportUnsuccessfulRequest({ error, body: payload });
 }

 return null;
};
