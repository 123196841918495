import {
  TextField as InternalTextField,
  TextFieldButton as InternalTextFieldButton
} from '@lessonnine/design-system.lib';

import {
  TextField as InternalTextFieldRebranded,
  TextFieldButton as InternalTextFieldButtonRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const TextfieldMap = {
  [DS_VERSIONS.default]: {
    TextField: InternalTextField,
    TextFieldButton: InternalTextFieldButton
  },
  [DS_VERSIONS.rebranded]: {
    TextField: InternalTextFieldRebranded,
    TextFieldButton: InternalTextFieldButtonRebranded
  }
};

export default TextfieldMap[CURRENT_DS_VERSION];
