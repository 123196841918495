import useAsyncData from 'src/viewLayer/components/hooks/useAsyncData';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import {
  validateIdentityProviderAndRedirect
} from 'src/dataLayer/remoteData/babbelAPI/validateIdentityProviderAndRedirect';
import { DASHBOARD_URL, getBusinessSuccessPageURL } from 'src/dataLayer/env';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';

export default function useB2BSessionRequest(identityProvider) {
  const { locale, environment } = useRouteParamsContext();
  const isNativeMobileView = useAppConfigContext().devicePath === 'login-mobile';
  const SSOReturnToUrl = isNativeMobileView ? getBusinessSuccessPageURL(locale, environment) : DASHBOARD_URL;

  const {
    makeRequest,
    inProgress,
    data
  } = useAsyncData(() => validateIdentityProviderAndRedirect(identityProvider, locale, SSOReturnToUrl));

  return {
    makeIdentityProviderValidationRequest: makeRequest,
    identityProviderValidationRequestInProgress: inProgress,
    isRedirecting: data?.isRedirecting
  };
};
