import React from 'react';
import TrackableButton from 'src/viewLayer/components/shared/TrackableButton';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { getOauthSignupURL } from 'src/dataLayer/env';
import OauthButtonIcon from './OauthButtonIcon';

export default ({ appType, provider }) => {
  const { locale } = useRouteParamsContext();
  const buttonIcon = OauthButtonIcon(provider);

  return (
    <TrackableButton
      dataTestid={`oauthButton-${provider}`}
      fullWidth={false}
      href={getOauthSignupURL(locale, provider)}
      buttonIcon={buttonIcon}
      minWidth="31%"
      title="Oauth button"
      trackingId={`${appType}:${provider}`}
    />
  );
};
