import styled from 'styled-components';
import { getColoursFromTheme, Box, Stack, Text } from 'src/viewLayer/components/DesignSystem';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

export const OauthContainer = styled(Stack)`
  width: 100%;

  @media only screen and ${devices.fromxxsmall} {
    max-width: 20rem;
  }
  @media only screen and ${devices.fromxsmall} {
    max-width: 21rem;
  }
`;

export const OauthSeparator = styled.hr`
  border: 0;
  color: ${({ theme }) => getColoursFromTheme(theme).authContainer.textColor};
  height: 1.5em;
  line-height: 1em;
  position: relative;
  outline: 0;
  text-align: center;
  width: 100%;
  &:before {
    content: '';
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.textColor};
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
    color: ${({ theme }) => getColoursFromTheme(theme).authContainer.textColor};
    content: attr(data-content);
    display: inline-block;
    line-height: 1.5em;
    padding: 0 .5em;
    position: relative;
  }
`;

export const OauthSeparatorText = styled(Text)`
  width: 100%;
`;

export const PrivacyTerms = styled(Box)`
  align-self: center;
  font-size: ${tokens.size.font.xsmall};
  margin-top: 1rem;
  text-align: center;

  @media only screen and ${devices.fromxxsmall} {
    width: 21.5rem;
  }

  @media only screen and ${devices.fromxsmall} {
    width: 26rem;
  }

  @media only screen and ${devices.fromsmall} {
    width: 24.75rem;
  }
`;
