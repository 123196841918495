const INTERPOLATION_REGEX = /%\{([\w-_]*)\}/g;

const throwInterpolationError = (template, options) => {
  throw new Error(`Cannot interpolate template: ${template} with options: ${JSON.stringify(options)}`);
};

export default (template, options) => {
  let templateMatch = template.match(INTERPOLATION_REGEX);

  let result;
  if (templateMatch) {
    templateMatch.forEach((toGetReplaced) => {
      const key = toGetReplaced.match(/\w+/)[0];
      const original = result || template;
      const replaceValue = options[key.toString()];
      if (!replaceValue) { throwInterpolationError(template, options); };
      return result = original.replace(toGetReplaced.toString(), replaceValue);
    });
    return result;
  }
  throwInterpolationError(template, options);
};
