import React from 'react';
import { Inline } from 'src/viewLayer/components/DesignSystem';
import OauthButton from 'src/viewLayer/components/shared/OauthButton';
import BusinessButton from 'src/viewLayer/components/shared/BusinessButton';
import InterpolateLink from 'src/viewLayer/components/shared/InterpolateLink';
import { t } from 'src/viewLayer/helpers/translations';
import { usePrivacyPath, useTermsPath } from 'src/viewLayer/navigation';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { UKRAINIAN_LOCALE } from 'src/dataLayer/localData/languageCodes';
import {
  OauthContainer,
  OauthSeparator,
  OauthSeparatorText,
  PrivacyTerms
} from './index.styled';

export const SocialLogin = ({ appType }) => {
  const { locale } = useRouteParamsContext();
  return (
    <OauthContainer className="Oauth" gap={16} padding={[16, 0, 0, 0]}>
      <Inline align="center" alignItems="center" gap={5} padding={[10, 0]}>
        <OauthSeparatorText>
          <OauthSeparator data-content={t('login.button.or_log_in_with')} />
        </OauthSeparatorText>
      </Inline>
      <Inline align="spaceBetween">
        <OauthButton appType={appType} provider="google" />
        <OauthButton appType={appType} provider="facebook" />
        <OauthButton appType={appType} provider="apple" />
      </Inline>
      { locale !== UKRAINIAN_LOCALE && <BusinessButton /> }
    </OauthContainer>
  );
};

export const TermsAndConditions = () => {
  const termsLink = {
    name: 'termsLink',
    path: useTermsPath(),
    translation: t('login_options.legal.terms_link')
  };

  const privacyLink = {
    name: 'privacyLink',
    path: usePrivacyPath(),
    translation: t('login_options.legal.privacy_link')
  };

  return (
    <PrivacyTerms>
      <InterpolateLink
        translation={t('login_options.legal.generic_gdpr_combined')}
        firstLink={ termsLink }
        secondLink={ privacyLink }
      />
    </PrivacyTerms>
  );
};
