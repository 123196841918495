import React from 'react';
import { Inline, Text } from 'src/viewLayer/components/DesignSystem';
import { t } from 'src/viewLayer/helpers/translations';
import TrackableLink from 'src/viewLayer/components/shared/TrackableLink';
import { getRegistrationUrl } from 'src/dataLayer/env';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';

export default () => {
  const { locale } = useRouteParamsContext();
  const { devicePath } = useAppConfigContext();

  if (devicePath !== 'login') return null;

  return (
    <Inline align="center" alignItems="center" gap={3}>
      <Text fontSize="small">
        { t('email.links.signup_question') }
      </Text>
      <TrackableLink
        data-testid="signup-link"
        href={ getRegistrationUrl(locale) }
        trackingId={'signup_link'}
        size="small"
        weight="strong"
      >
        { t('email.links.signup_action') }
      </TrackableLink>
    </Inline>
  );
};
