import styled from 'styled-components';
import { Box, Stack } from 'src/viewLayer/components/DesignSystem';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import { getColoursFromTheme } from 'src/viewLayer/components/DesignSystem';

export const LoginEmailPageContainer = styled(Stack)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
  height: 100%;
  min-height: calc(100% - 4rem);
  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  }
`;

export const FormContainer = styled(Stack)`
  margin: 0 auto;
  width: 100%;

  @media only screen and ${devices.fromxxsmall} {
    width: 20rem;
  }
  @media only screen and ${devices.fromxsmall} {
    width: 21rem;
  }
`;

export const ActionsContainer = styled(Box)`
  display: block;
  margin: 2.125rem auto 0;
  width: 100%;
`;

export const InputFrame = styled(Box)`
   padding: 2.375rem 0 0 0;

   @media only screen and ${devices.fromsmall} {
     padding: 2rem 0 0 0;
   }
`;
