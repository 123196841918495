import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { SocialLogin, TermsAndConditions } from 'src/viewLayer/components/shared/SharedViewComponent';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';
import { Stack } from 'src/viewLayer/components/DesignSystem';

import { BackgroundItemsContainer, Card, CardContainer, CardLayout } from './index.styled';

const WebCardLayout = ({
  appType,
  title,
  subtitle,
  BackgroundItems,
  Image,
  hideTermsAndConditions = false,
  children
}) => (
  <CardLayout align="center">
    <CardContainer align="center" className="CardLayoutComponent">
      <Card
        alignItems="center"
        padding={{
          xxsmall: [36, 8, 10, 8],
          small: [80, 8, 24, 8]
        }}
      >
        <Header Image={Image} title={title} subtitle={subtitle} />
        {children}
        <SocialLogin appType={appType} />
        { !hideTermsAndConditions && <TermsAndConditions /> }
      </Card>
      <BackgroundItemsContainer>
        {BackgroundItems && BackgroundItems.map((BackgroundItem, index) => (
            <BackgroundItem key={index} />
        ))}
      </BackgroundItemsContainer>
    </CardContainer>
    <Footer />
  </CardLayout>
);

const NativeMobileCardLayout = ({ title, subtitle, Image, children }) => (
  <CardLayout align="center">
    <CardContainer webview>
      <Stack
        padding={{
            xxsmall: [80, 0, 10, 0],
            small: [80, 5, 24, 5]
        }}
        alignItems="center"
      >
        <Header Image={Image} title={title} subtitle={subtitle} />
        {children}
      </Stack>
    </CardContainer>
  </CardLayout>
);

export default (props) => {
  const { devicePath } = useAppConfigContext();
  return (
    devicePath === 'login' ? <WebCardLayout {...props} /> : <NativeMobileCardLayout {...props} />
  );
};
