import { useState } from 'react';

const EMPTY_STRING = '';
const initialValue = {
  errorKey: EMPTY_STRING,
  inputValue: EMPTY_STRING,
  isFocused: true
};

/*
 * Generic state for input elements that provide an input value and error
 * key.
 */
export default (initialState = initialValue) => {
  const [inputValue, setInputValue] = useState(initialState.inputValue);
  const [errorKey, setErrorKey] = useState(initialState.errorKey);
  const [isFocused, setFocus] = useState(initialState.isFocused);

  // Clears error when input is updated
  const setInputValueFromEvent = (event) => {
    setInputValue(event.target.value);
    setErrorKey(initialState.errorKey);
    setFocus(initialState.isFocused);
  };

  // Resets both items
  const resetInputState = () => {
    setInputValue(initialValue.inputValue);
    setErrorKey(initialValue.errorKey);
    setFocus(initialValue.isFocused);
  };

  return {
    inputValue,
    errorKey,
    isFocused,
    setFocus,
    resetInputState,
    setInputValue: setInputValueFromEvent,
    setErrorKey
  };
};
