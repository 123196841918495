import {
  Spinner as InternalSpinner
} from '@lessonnine/design-system.lib';

import {
  Spinner as InternalSpinnerRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Spinner = {
  [DS_VERSIONS.default]: InternalSpinner,
  [DS_VERSIONS.rebranded]: InternalSpinnerRebranded
};

export default Spinner[CURRENT_DS_VERSION];
