/* eslint-disable no-restricted-imports */
import { getMyEnv } from '@lessonnine/my.js';
import { getSubdomainFromLocale } from 'src/dataLayer/localData/languageCodes';
import { getZendeskLocale } from 'src/dataLayer/localData/zendeskConfig';
import { getReturnToUrl } from 'src/dataLayer/localData/queryParams';
import { US_SUPPORTED_LOCALES, US_SUPPORTED_GEOS } from 'src/dataLayer/localData/USSupportedGeos';
import interpolate from './helpers/interpolate';
import { getRawQueryString } from '../localData/queryParams';
import process from 'process';

const {
  uuid,
  environment,
  staticBaseUrlTemplate,
  myBaseUrl,
  apigatewayBaseUrl,
  assetUrl,
  homeBaseUrl,
  locale,
  geoData,
  signInUrl,
  rollbarAccessToken,
  awsSessionEndpoint,
  awsSessionMethod
} = getMyEnv();

// User Data
export const UUID = uuid;
// TODO: Always get locale from here instead of useRouteParamsContext hook
export const LOCALE = locale;
export const ROLLBAR_ACCESS_TOKEN = rollbarAccessToken;

// Env
export const RUNTIME_ENVIRONMENT = environment;
export const isPrivateEnv = () => new Set(['development', 'test', undefined]).has(environment);

// URLs
export const ASSET_URL = assetUrl;
export const APIGATEWAY_BASE_URL = apigatewayBaseUrl;
export const DASHBOARD_URL = `${myBaseUrl}/dashboard`;
export const TRACKING_URL = `${APIGATEWAY_BASE_URL}/v1/events`;
export const ROLLBAR_API_URL = 'https://api.rollbar.com/api/1/item/';
export const SIGN_IN_URL = signInUrl;
export const AWS_SESSION_ENDPOINT = awsSessionEndpoint;
export const AWS_SESSION_METHOD = awsSessionMethod;

const DEFAULT_REGISTRATION_FLOW = 'language_selection';
const DASHBOARD_REGISTRATION_FLOW = 'language_selection_reg_dashboard';

const OAUTH_SIGNUP_URL_TEMPLATE = `${homeBaseUrl}/%{locale}/` +
  `oauth_session/new?provider=%{provider}&return_to=%{returnTo}`;
const faqURLTemplate = 'https://support.babbel.com/hc/%{locale}/categories/200333238-Frequently-asked-questions';

// The learning language is hardcoded since we go to the language_selection flow where it is not relevant
export const getRegistrationUrl = (locale) => {
  if (US_SUPPORTED_LOCALES.includes(locale) && US_SUPPORTED_GEOS.includes(geoData?.countryCode3)) {
    return `${myBaseUrl}/${locale}/welcome${getRawQueryString()}`;
  }
  // Ukrainian users will be sent to the dashboard after registration
  const flowName = locale === 'uk' ? DASHBOARD_REGISTRATION_FLOW : DEFAULT_REGISTRATION_FLOW;
  return `${myBaseUrl}/${locale}/product-preview/ENG/${flowName}/node/0${getRawQueryString()}`;
};

export const getIndexPageURL = (locale) =>
  interpolate(staticBaseUrlTemplate, { subdomain: getSubdomainFromLocale(locale) });

export const getForgotPasswordURL = (locale) => {
  const accountsURL = interpolate(staticBaseUrlTemplate, { subdomain: 'accounts' });
  const returnToURL = getReturnToUrl(window.location.search);
  // Only set the returnToParam if it is not the dashboard url, since home takes care of sending to dashboard anyways
  const returnToParam = (returnToURL && returnToURL !== encodeURIComponent(DASHBOARD_URL))
    ? `?return_to=${ returnToURL }`
    : '';
  return `${accountsURL}/${locale}/accounts/password/new${returnToParam}`;
};

export const getOauthSignupURL = (locale, provider) =>
  interpolate(OAUTH_SIGNUP_URL_TEMPLATE, {
    locale,
    provider,
    returnTo: getReturnToUrl(window.location.search)
  });

export const getBusinessSuccessPageURL = (locale, environment) =>
  `${myBaseUrl}/${locale}/${environment}/login-mobile/business/success`;

export const getFAQURL = () => {
  return interpolate(faqURLTemplate, { locale: getZendeskLocale() });
};

// Buildtime
export const BABBEL_APP_VERSION = process.env.BABBEL_APP_VERSION;
// TODO: needs to be exposed via repositories.terraform
export const BEARER_TOKEN = process.env.BEARER_TOKEN;
