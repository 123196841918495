import styled from 'styled-components';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import { getColoursFromTheme, Box, Stack } from 'src/viewLayer/components/DesignSystem';
import { getThemeDefaultProps } from 'src/viewLayer/styling/helpers';

export const AppComponentWithBackground = styled(Stack)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  height: 100%;
  min-height: 100vh;
  /* mobile viewport iphone se bug fix */
  min-height: -webkit-fill-available;
`;

export const LoginBusinessPageContainer = styled(Box)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
  min-height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  }
`;

export const FooterContainer = styled(Stack)`
  background-color: transparent;
`;

AppComponentWithBackground.defaultProps = getThemeDefaultProps();
