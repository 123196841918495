import {
  NavBarWrapper as InternalNavBarWrapper
} from '@lessonnine/design-system.lib';

import {
  NavBarWrapper as InternalNavBarWrapperRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const NavBarWrapper = {
  [DS_VERSIONS.default]: InternalNavBarWrapper,
  [DS_VERSIONS.rebranded]: InternalNavBarWrapperRebranded
};

export default NavBarWrapper[CURRENT_DS_VERSION];
