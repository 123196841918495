/* eslint-disable max-len */
import React from 'react';
import { BigLockContainer } from '../index.styled';

export default () => (
  <BigLockContainer>
    <svg className="BigLockSVG" width="223" height="241" viewBox="0 0 223 241" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M174.015 105.034C184.892 79.0373 172.966 49.1142 147.126 38.3034C121.307 27.5012 91.6523 39.9883 80.7546 65.943L63.9476 103.954C62.4959 107.238 63.9806 111.076 67.2638 112.528C70.5471 113.979 74.3855 112.495 75.8372 109.211L92.6962 71.0826L92.7214 71.0224C100.893 51.4904 123.05 42.3223 142.109 50.2961C161.166 58.2692 170.193 80.4803 162.025 100.011C162.024 100.013 162.023 100.015 162.023 100.017L157.155 111.388C155.734 114.707 157.291 118.549 160.622 119.942C163.914 121.32 167.701 119.785 169.106 116.504L174.011 105.045L174.015 105.034ZM171.25 103.871C171.249 103.872 171.249 103.874 171.248 103.876L166.348 115.323C165.591 117.09 163.552 117.916 161.779 117.175C159.986 116.424 159.148 114.356 159.913 112.569L164.785 101.186L164.79 101.174C173.587 80.1492 163.889 56.1565 143.267 47.5286C122.644 38.9006 98.7503 48.8393 89.9538 69.8645L89.9402 69.8971L73.0934 107.998C72.3117 109.766 70.2449 110.566 68.477 109.784C66.7091 109.002 65.9097 106.935 66.6913 105.167L83.51 67.13L83.5155 67.1168C93.784 42.642 121.71 30.9217 145.968 41.071C170.24 51.2258 181.499 79.3658 171.25 103.871Z" fill="#140B41" />
      <rect width="123.371" height="99.3376" transform="matrix(-0.922515 -0.385961 -0.385961 0.922515 172.646 135.051)" fill="#FF9666" />
      <path fillRule="evenodd" clipRule="evenodd" d="M89.7887 159.055C86.1867 155.26 84.9516 149.553 87.1008 144.416C90.0038 137.478 97.9821 134.206 104.921 137.109C111.859 140.012 115.131 147.99 112.228 154.929C110.079 160.066 105.148 163.193 99.917 163.293C99.8451 163.665 99.734 164.037 99.5816 164.401L93.9372 177.892C93.2977 179.42 91.5402 180.141 90.0118 179.502L85.2004 177.489C83.6719 176.849 82.9512 175.092 83.5907 173.563L89.2351 160.072C89.3875 159.708 89.5737 159.368 89.7887 159.055Z" fill="#140B41" />
      <circle r="5.60777" transform="matrix(-0.922515 -0.385961 -0.385961 0.922515 151.682 143.648)" fill="#FF7433" />
      <path fillRule="evenodd" clipRule="evenodd" d="M154.775 138.381C153.924 137.881 152.965 137.595 151.98 137.547C150.994 137.499 150.012 137.691 149.116 138.105C148.221 138.52 147.439 139.145 146.838 139.927C146.237 140.709 145.835 141.626 145.665 142.598C145.496 143.57 145.564 144.568 145.864 145.508C146.165 146.448 146.689 147.301 147.391 147.994C147.587 148.188 147.904 148.186 148.098 147.989C148.292 147.793 148.29 147.476 148.093 147.282C147.506 146.703 147.068 145.989 146.817 145.203C146.566 144.417 146.509 143.582 146.65 142.77C146.792 141.957 147.129 141.191 147.631 140.536C148.134 139.882 148.788 139.359 149.536 139.013C150.285 138.666 151.107 138.506 151.931 138.546C152.755 138.586 153.557 138.825 154.268 139.243C154.98 139.661 155.58 140.245 156.016 140.945C156.453 141.645 156.713 142.441 156.775 143.263C156.796 143.539 157.036 143.745 157.312 143.724C157.587 143.703 157.793 143.463 157.773 143.188C157.698 142.204 157.387 141.253 156.865 140.416C156.343 139.579 155.626 138.88 154.775 138.381Z" fill="#140B41" />
      <circle r="5.60777" transform="matrix(-0.922515 -0.385961 -0.385961 0.922515 68.9104 109.018)" fill="#FF7433" />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.7185 112.233C64.2379 113.072 64.9527 113.772 65.8018 114.274C66.6509 114.777 67.6091 115.066 68.5944 115.117C69.5797 115.168 70.5627 114.98 71.4593 114.569C72.356 114.157 73.1396 113.535 73.7432 112.754C74.3467 111.974 74.7522 111.059 74.925 110.087C75.0978 109.116 75.0327 108.117 74.7353 107.176C74.652 106.913 74.3711 106.767 74.1078 106.85C73.8445 106.934 73.6985 107.215 73.7818 107.478C74.0305 108.265 74.085 109.1 73.9405 109.912C73.796 110.724 73.4569 111.49 72.9521 112.142C72.4474 112.795 71.7921 113.316 71.0422 113.66C70.2924 114.004 69.4703 114.161 68.6463 114.119C67.8224 114.076 67.0211 113.834 66.311 113.414C65.6009 112.994 65.0031 112.408 64.5688 111.706C64.1345 111.005 63.8766 110.209 63.8171 109.386C63.7577 108.563 63.8985 107.738 64.2275 106.981C64.3376 106.728 64.2216 106.433 63.9683 106.323C63.7151 106.213 63.4205 106.329 63.3104 106.582C62.917 107.487 62.7487 108.474 62.8197 109.458C62.8908 110.442 63.1992 111.394 63.7185 112.233Z" fill="#140B41" />
      <path fillRule="evenodd" clipRule="evenodd" d="M199.294 129.664C199.257 130.215 198.78 130.632 198.229 130.594L188.741 129.954C188.19 129.917 187.774 129.44 187.811 128.889C187.848 128.338 188.325 127.921 188.876 127.959L198.364 128.599C198.915 128.636 199.331 129.113 199.294 129.664Z" fill="#FF7433" />
      <path fillRule="evenodd" clipRule="evenodd" d="M188.497 146.469C188.081 146.833 187.45 146.79 187.086 146.374L181.011 139.419C180.648 139.003 180.69 138.372 181.106 138.008C181.522 137.645 182.154 137.687 182.517 138.103L188.592 145.058C188.956 145.474 188.913 146.106 188.497 146.469Z" fill="#FF7433" />
      <path fillRule="evenodd" clipRule="evenodd" d="M190.97 109.841C190.607 109.425 189.975 109.382 189.559 109.745L182.605 115.821C182.189 116.184 182.146 116.816 182.509 117.232C182.873 117.648 183.504 117.69 183.92 117.327L190.875 111.252C191.291 110.888 191.334 110.256 190.97 109.841Z" fill="#FF7433" />
    </svg>
  </BigLockContainer>
);
