import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';
import { getQueryParamsString } from 'src/dataLayer/localData/queryParams';

const useAboutBabbelBasePath = () => {
  return `https://about.babbel.com/${ useRouteParamsContext().locale }/`;
};

const useBasePath = () => {
  const { locale, environment } = useRouteParamsContext();
  const { devicePath } = useAppConfigContext();

  // when we visit /{locale}/authentication/ the path will be undefined
  // we need to handle undefined in here, otherwise it will redirect to
  // /{locale}/authentication/undefined/email
  const redirectionAppSlug = devicePath || 'login';
  return `/${locale}/${environment}/${redirectionAppSlug}/`;
};

// a localised page exists only for german
const useBabbelBusinessPath = () => {
  const { locale } = useRouteParamsContext();
  return locale === 'de' ? 'https://www.babbelfuerunternehmen.de/pages/' : 'https://www.babbelforbusiness.com/pages/';
};

export const useEmailPagePath = () => {
  const { queryParams } = useRouteParamsContext();
  return useBasePath() + 'email' + getQueryParamsString(queryParams);
};

export const usePasswordPagePath = () => {
  const { queryParams } = useRouteParamsContext();
  return useBasePath() + 'password' + getQueryParamsString(queryParams);
};

export const useBusinessPagePath = () => {
  const { queryParams } = useRouteParamsContext();
  return useBasePath() + 'business' + getQueryParamsString(queryParams);
};

export const useBusinessErrorPagePath = () => {
  return useBasePath() + 'business/error';
};

export const usePrivacyPath = () => {
  return useAboutBabbelBasePath() + 'privacy';
};

export const useTermsPath = () => {
  return useAboutBabbelBasePath() + 'terms';
};

// locales in array use the business privacy page, the other locales the default privacy page
export const useBusinessPrivacyPath = () => {
  const { locale } = useRouteParamsContext();
  const businessPrivacyPath = useBabbelBusinessPath() + 'privacy-policy';
  const privacyPath = usePrivacyPath();
  return (['en', 'en_GB', 'pl'].includes(locale)) ? businessPrivacyPath : privacyPath;
};

// locales in array use localised business data page, the other locales the english business data page
export const useBusinessDataPath = () => {
  const { locale } = useRouteParamsContext();
  const businessDataPath = useBabbelBusinessPath() + 'dse';
  return (['es', 'fr', 'it', 'pt'].includes(locale)) ? `${businessDataPath}-${locale}` : businessDataPath;
};
