import awsApiClient from './awsApiClient';
import { reportAPIFailure } from 'src/dataLayer/remoteData/rollbar';
import { LOCALE } from 'src/dataLayer/env';

export const authenticate = () =>
  awsApiClient.authenticate(LOCALE);

const catchApiClientFetch = async (requiresAuthentication, verb, payload) => {
  try {
    if (requiresAuthentication) {
      await authenticate();
    }
    return await awsApiClient.fetch({ verb, ...payload });
  } catch (error) {
    reportAPIFailure(error, payload);
  }
};

export const authenticatedGet = (payload) =>
  catchApiClientFetch(true, 'GET', payload);
