import {
  BabbelThemeProvider as InternalBabbelThemeProvider,
  useTheme as InternalUseTheme
} from '@lessonnine/design-system.lib';

import {
  BabbelThemeProvider as InternalBabbelThemeProviderRebranded,
  useTheme as InternalUseThemeRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const ThemeMap = {
  [DS_VERSIONS.default]: {
    BabbelThemeProvider: InternalBabbelThemeProvider,
    useTheme: InternalUseTheme
  },
  [DS_VERSIONS.rebranded]: {
    BabbelThemeProvider: InternalBabbelThemeProviderRebranded,
    useTheme: InternalUseThemeRebranded
  }
};

export default ThemeMap[CURRENT_DS_VERSION];
