import requestAuthSessionParams from 'src/dataLayer/remoteData/babbelAPI/requestAuthSessionParams';
import useAsyncData from 'src/viewLayer/components/hooks/useAsyncData';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';

export default function useHandleSessionRequest(email) {
  const { locale } = useRouteParamsContext();

  const {
    data,
    makeRequest,
    inProgress
  } = useAsyncData(() => requestAuthSessionParams({ locale, email }));

  return {
    sessionCode: data ? data.sessionCode : null,
    captchaConfig: data ? data.captchaConfig : null,
    makeAuthSessionRequest: makeRequest,
    authSessionRequestInProgress: inProgress
  };
};
