/* eslint-disable max-lines */
import React, { useEffect } from 'react';

// navigation
import { useNavigate } from 'react-router-dom';
import ForgotPasswordLink from './ForgotPasswordLink';
import { useEmailPagePath } from '../../../navigation';

// context, state and remote data
import useTrackPageShown from 'src/viewLayer/components/hooks/useTrackPageShown';

// view components
import CardLayout from './../CardLayout';
import PasswordImage from './Images/Password.svg';
import KeysImage from './Images/Keys.svg';
import BigLockImage from './Images/BigLock.svg';
import PasswordInput from 'src/viewLayer/components/shared/Inputs/PasswordInput';
import TrackableButton from '../../shared/TrackableButton';
import BackButton from '../BackButton';
import ChangeEmailLink from 'src/viewLayer/components/shared/HintLinks/ChangeEmailLink';
import { isRebranded } from 'src/viewLayer/components/DesignSystem';
import {
  ActionsContainer,
  FormContainer,
  InputFrame,
  LoginPasswordPageContainer
} from './index.styled';

import { t } from 'src/viewLayer/helpers/translations';

export default function LoginPasswordPage({
  appType,
  captchaFeature,
  captchaResponseToken,
  email,
  errorKey,
  isFocused,
  inProgress,
  path,
  onChange,
  onSubmit,
  password
}) {
  const navigate = useNavigate();
  const emailPagePath = useEmailPagePath();
  useTrackPageShown({ appType, pageType: path });
  useEffect(() => {
    if (!email) navigate(emailPagePath, { replace: true });
  }, [email, emailPagePath, navigate]);

  return (
    <LoginPasswordPageContainer>
      <CardLayout
        title={ t('password.title') }
        subtitle={ email }
        Image={ PasswordImage }
        BackgroundItems={ !isRebranded && [KeysImage, BigLockImage] }>
        <ChangeEmailLink />
        <BackButton trackingId="backbutton-password-to-email" path={ path }/>
        <FormContainer>
          <form onSubmit={ onSubmit }>
            <InputFrame>
              <PasswordInput
                errorKey={ errorKey }
                isFocused={ isFocused }
                onChangeHandler={ onChange }
                value={ password }
                disabled={ inProgress } />
            </InputFrame>
          </form>
          <ForgotPasswordLink/>
          { captchaFeature }
          <ActionsContainer>
            <TrackableButton
              id="uat-continue-button"
              disabled={ password.length === 0 || inProgress || !captchaResponseToken }
              inProgress={ inProgress }
              onClick={ onSubmit }
              title="Login button"
              trackingId="password_submit">
              { t('password.button.login') }
            </TrackableButton>
          </ActionsContainer>
        </FormContainer>
      </CardLayout>
    </LoginPasswordPageContainer>
  );
};
