export const REST_OF_AMERICAS_GEOS = [
  'ABW',
  'AIA',
  'ANT',
  'ARG',
  'ATG',
  'BES',
  'BHS',
  'BLM',
  'BLZ',
  'BMU',
  'BOL',
  'BRB',
  'CHL',
  'COK',
  'COL',
  'CRI',
  'CUB',
  'CUW',
  'CYM',
  'DMA',
  'DOM',
  'ECU',
  'FLK',
  'GLP',
  'GRD',
  'GTM',
  'GUF',
  'GUY',
  'HND',
  'HTI',
  'JAM',
  'KNA',
  'LCA',
  'MAF',
  'MEX',
  'MSR',
  'MTQ',
  'NIC',
  'PAN',
  'PER',
  'PRI',
  'PRY',
  'SGS',
  'SLV',
  'SPM',
  'SUR',
  'SXM',
  'TCA',
  'TTO',
  'URY',
  'VCT',
  'VEN',
  'VGB',
  'VIR'
];
