const localStorageAvailable = () => {
  try {
    if (window.localStorage) return true;
  } catch (e) {
    return false;
  }
};

const safeLocalStorage = {
  get: (key) => {
    if (localStorageAvailable()) return localStorage.getItem(key);
  },
  set: (key, value) => {
    try {
      // if storage is full, an error will be thrown when setting,
      // so it needs to be caught
      if (localStorageAvailable()) {
        return localStorage.setItem(key, value);
      }
    } catch (e) {}
  },
  remove: (key) => {
    try {
      if (localStorageAvailable()) {
        return localStorage.removeItem(key);
      }
    } catch (e) {}
  }
};

export default safeLocalStorage;
