import { CAPTCHA_TARGET_ID } from 'src/dataLayer/localData/captcha';
import renderCaptcha from 'src/viewLayer/components/shared/CaptchaFeature/renderCaptcha';
import { reportError } from 'src/dataLayer/remoteData/rollbar/reporter';
import { executeCaptcha, isInvisible, extractCaptchaKey } from 'src/viewLayer/components/shared/CaptchaFeature/helpers';

const onCaptchaResponse = (responseToken, setCaptchaResponseToken) => {
  setCaptchaResponseToken(responseToken);
};

const onCaptchaError = (error, setCaptchaResponseToken, setShowErrorMessage, setCaptchaWidgetID) => {
  reportError(error);
  setCaptchaWidgetID(undefined);
  setCaptchaResponseToken(undefined);
  setShowErrorMessage(true);
};

export const loadCaptcha = async ({
  sessionCode,
  locale,
  showErrorMessage,
  setShowErrorMessage,
  setCaptchaResponseToken,
  captchaConfig,
  captchaWidgetID,
  setCaptchaWidgetID,
  setStoredSessionCode
}) => {
  if (showErrorMessage) return;
  if (captchaWidgetID !== undefined) return;
  setStoredSessionCode(sessionCode);

  const onSuccess = (responseToken) => onCaptchaResponse(responseToken, setCaptchaResponseToken);

  const onError = (error) => onCaptchaError(error, setCaptchaResponseToken, setShowErrorMessage, setCaptchaWidgetID);

  const onExpired = () => {
    setCaptchaResponseToken(undefined);
    if (isInvisible(captchaConfig.provider)) executeCaptcha(captchaConfig.provider, captchaWidgetID);
  };

  const renderCaptchaArgs = {
    targetId: CAPTCHA_TARGET_ID,
    captchaKey: extractCaptchaKey(captchaConfig),
    locale,
    onSuccess,
    onError,
    onExpired,
    provider: captchaConfig?.provider
  };

  setCaptchaWidgetID(await renderCaptcha(renderCaptchaArgs));
};
