import styled from 'styled-components';
import { Layout } from 'src/viewLayer/components/DesignSystem';
import devices from 'src/viewLayer/styling/mixins/media-queries';

export const CaptchaContainer = styled(Layout)`
  display: ${(props) => (props.invisible ? 'none' : 'flex')};
  margin-top: 1.25rem;
  min-height: 5.188rem;
  transform: scale(0.9);

  @media only screen and ${devices.fromxsmall} {
    transform: none;
  }
`;
