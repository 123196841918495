import React from 'react';
import InterpolateLink from 'src/viewLayer/components/shared/InterpolateLink';
import { useBusinessPrivacyPath, useBusinessDataPath } from 'src/viewLayer/navigation';
import { t } from 'src/viewLayer/helpers/translations';
import { isRebranded, Stack, Text } from 'src/viewLayer/components/DesignSystem';

const DataProcessingSection = () => {
  const businessPrivacyLink = {
    name: 'businessPrivacyLink',
    path: useBusinessPrivacyPath(),
    translation: t('login.b2b.data_processing.business_privacy_link')
  };

  const businessDataLink = {
    name: 'businessDataLink',
    path: useBusinessDataPath(),
    translation: t('login.b2b.data_processing.business_data_link')
  };

  return (
    <Stack gap={ isRebranded ? 24 : 16}>
      <Text
        fontSize={ isRebranded
          ? 'small'
          : { xxxsmall: 10, small: 'xsmall' }
        }
        fontWeight={ isRebranded
          ? { xxxsmall: 400, small: 600 }
          : 700
        }
      >
        { t('login.b2b.data_processing.title') }
      </Text>
      <InterpolateLink
        translation={t('login.b2b.data_processing.legal_copy')}
        firstLink={ businessPrivacyLink }
        secondLink={ businessDataLink }
      />
    </Stack>
  );
};

export default DataProcessingSection;
