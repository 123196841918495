import emitEvent from './emitEvent';
import { validateEventProps } from './validateEventProps';
import { reportTrackerFailure } from '../rollbar';
import { LOCALE } from 'src/dataLayer/env';

export default ({ name, version, getCalltimePayload }) => (payload) => {
  const { learnLanguageCode } = payload;
  validateEventProps({ name, version, locale: LOCALE, learnLanguageCode, ...payload });

  return emitEvent({
    name,
    version,
    payload: {
      locale: LOCALE,
      learn_language_alpha3: learnLanguageCode,
      ...getCalltimePayload(payload)
    },
    error: (error) => reportTrackerFailure(error, name, payload)
  });
};
