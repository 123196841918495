import { DASHBOARD_URL } from 'src/dataLayer/env';

const BABBEL_URL_REGEX = /([a-z0-9]+\.)?(babbel)\.(com|cn|io)$/;
const ENCODED_RETURN_TO_REGEX = /return_to=https?%3A%2F%2F/;

const ENV_DOMAINS = ['com', 'cn', 'test'];
const ENV_DOMAINS_REGEX = new RegExp(`(${ENV_DOMAINS.map((env) => `.${env}`).join('|')})`);

export const getRawQueryString = () => `?${new URLSearchParams(location.search).toString()}`;

export const isValidBabbelReturnUrl = (returnToFromQuery) => {
  try {
    const decodedReturnToURLObject = new URL(decodeURIComponent(returnToFromQuery));
    return BABBEL_URL_REGEX.test(decodedReturnToURLObject.hostname);
  } catch {
    return false;
  }
};

const getEnvDomainToUpdateReturnTo = (returnToURL) => {
  const currentURL = window.location.href;

  return ENV_DOMAINS.reduce(
    (domainToReplace, domain) => {
      const regexEnvDomain = new RegExp(`(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.(${domain})+(\/\S*)?`);
      const isDomainEnvInCurrentURL = regexEnvDomain.test(currentURL);
      const isDomainEnvInReturnToURL = regexEnvDomain.test(returnToURL);
      return isDomainEnvInCurrentURL && !isDomainEnvInReturnToURL ? domain : domainToReplace;
    },
    null
  );
};

const getReturnUrlWithValidEnvironment = (returnToURL) => {
  const envToReplace = getEnvDomainToUpdateReturnTo(returnToURL);

  if (!envToReplace) return returnToURL;

  const updatedReturnToURL = returnToURL.replace(
    ENV_DOMAINS_REGEX,
    `.${envToReplace}`
  );
  return updatedReturnToURL;
};

// getValidQueryParams is used to get valid query params when changing the route.
// Invalid return_to params will be removed on route change.
export const getValidQueryParams = (queryParamsString) => {
  const queryParams = new URLSearchParams(queryParamsString);
  const returnToURL = queryParams.get('return_to');

  if (!isValidBabbelReturnUrl(returnToURL)) queryParams.delete('return_to');

  return queryParams;
};

// Return a string of the validated query params
export const getQueryParamsString = (queryParams) =>
  queryParams.toString() !== '' ? `?${queryParams.toString()}` : '';

/*
 * Returns the `return_to` query param of a URL if the value is a valid Babbel url.
 * If the return_to param is missing or is not a Babbel url, it returns the dashboard url.
 */
export const getReturnToUrl = (queryParams, encoded = true) => {
  // return the dashboard URL if the url is not encoded, since its query params will be trimmed otherwise
  if (!ENCODED_RETURN_TO_REGEX.test(window.location.href)) {
    return encoded ? encodeURIComponent(DASHBOARD_URL) : DASHBOARD_URL;
  }

  const queryParamsObj = typeof queryParams === 'string' ? new URLSearchParams(queryParams) : queryParams;

  const { return_to: returnToURL } = Object.fromEntries(queryParamsObj);

  const decodedURL = isValidBabbelReturnUrl(returnToURL)
    ? getReturnUrlWithValidEnvironment(returnToURL)
    : DASHBOARD_URL;

  return encoded ? encodeURIComponent(decodedURL) : decodedURL;
};
