import React, { useEffect } from 'react';
import initZendeskWidget from './initZendeskWidget';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';

const DELAY_MS = 5000; // delay loading widget for better performance

export default () => {
  const { devicePath } = useAppConfigContext();

  useEffect(() => {
    if (devicePath === 'login') {
      const timeoutRef = setTimeout(async () => {
        initZendeskWidget();
      }, DELAY_MS);

      // clean up
      return () => clearTimeout(timeoutRef);
    }
  }, [devicePath]);

  return <></>;
};
