import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useBusinessPagePath } from 'src/viewLayer/navigation';

/*
 * Used as the redirect handler for invalid business routes
 * Does not create a history item.
 */

export default function UnauthorizedBusinessRedirect() {
  const businessPath = useBusinessPagePath();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(businessPath, { replace: true });
  }, [businessPath, navigate]);

  return null;
};
