import React from 'react';
import { getForgotPasswordURL } from 'src/dataLayer/env';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';
import { onForgotPasswordClicked } from 'src/viewLayer/helpers/safeNativeReporter';
import { t } from 'src/viewLayer/helpers/translations';
import { ForgotPasswordTrackableLink } from './index.styled';

const WebForgotPasswordLink = ({ locale }) => (
  <ForgotPasswordTrackableLink
    className="ForgotPasswordLink"
    data-testid="forgot-password-link-web"
    trackingId="forgot_password_link"
    href={getForgotPasswordURL(locale)}
    size="small"
    weight="strong"
  >
    {t('password.links.forgot_password')}
  </ForgotPasswordTrackableLink>
);

const NativeMobileForgotPasswordLink = () => (
  <ForgotPasswordTrackableLink
    className="ForgotPasswordLink"
    data-testid="forgot-password-link-mobile"
    onClick={onForgotPasswordClicked}
    trackingId="forgot_password_link"
    size="small"
    weight="strong"
  >
    {t('password.links.forgot_password')}
  </ForgotPasswordTrackableLink>
);

const ForgotPasswordLink = () => {
  const { locale } = useRouteParamsContext();
  const { devicePath } = useAppConfigContext();
  return (
    devicePath === 'login-mobile' ? <NativeMobileForgotPasswordLink /> : <WebForgotPasswordLink locale={locale} />
  );
};

export default ForgotPasswordLink;
