/*
  this module wraps each function declared in `rawStateInterface` with `react#setState`
  - SUPPLIED FUNCTIONS (`rawStateInterface`)
    - an object of functions
    - each function must return an object that describes how `state` should change
  - RETURNED FUNCTIONS (`stateInterface`)
    - each function will update `state` when called, as described in `rawStateInterface`
*/

import { useState } from 'react';

export default (initialState, rawStateInterface) => {
  const [state, setState] = useState(initialState);

  const stateInterface = {};
  Object.entries(rawStateInterface)
    .forEach(([stateChangeName, getStateChanges]) => {
      stateInterface[stateChangeName] = (...args) => {
        const stateChanges = getStateChanges(...args);
        const upcomingState = { ...state, ...stateChanges };
        return setState(upcomingState);
      };
    });

  return {
    state,
    setState: stateInterface
  };
};
