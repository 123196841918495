export const CAPTCHA_CALLBACK = 'CAPTCHA_CALLBACK';
export const CAPTCHA_MAX_LOAD_TIME = 5000;
export const INVISIBLE_CAPTCHA_TYPE = 'invisible';
export const CAPTCHA_TARGET_ID = 'CAPTCHA_TARGET_ID';
export const CAPTCHA_ERROR_KEY = 'common.error.unknown';
export const NO_CAPTCHA_REQUIRED_STATUS = 'No captcha response required by the backend';
export const CAPTCHA_PROVIDER_CONFIG = {
  recaptcha: {
    windowMethod: 'grecaptcha',
    scriptURL: `https://www.google.com/recaptcha/api.js?onload=${CAPTCHA_CALLBACK}&render=explicit`,
    type: 'normal' // aka checkbox (normal is used in the docs)
  },
  hcaptcha: {
    windowMethod: 'hcaptcha',
    scriptURL: `https://js.hcaptcha.com/1/api.js?onload=${CAPTCHA_CALLBACK}&render=explicit`,
    type: INVISIBLE_CAPTCHA_TYPE // would be great if this could actually come from the backend
  }
};

