import {
  Badge as InternalBadge
} from '@lessonnine/design-system.lib';

import {
  Tag as InternalBadgeRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Badge = {
  [DS_VERSIONS.default]: InternalBadge,
  [DS_VERSIONS.rebranded]: InternalBadgeRebranded
};

export default Badge[CURRENT_DS_VERSION];
