import React, { createContext, useContext } from 'react';

export default (mapPropsToValue) => {
  const Context = createContext({});

  const Provider = (props) => (
    <Context.Provider value={mapPropsToValue(props)} >
      { props.children }
    </Context.Provider>
  );

  return {
    useContext: () => useContext(Context),
    Provider
  };
};
