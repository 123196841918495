import setHeaders from './setHeaders';
import { successHandler, failureHandler } from './responseHandlers';

const customFetch = ({ verb, payload, url }) =>
  window.fetch(url, {
    method: verb,
    headers: setHeaders(),
    body: JSON.stringify(payload)
  });

export default (params) =>
  customFetch(params)
    .then(successHandler)
    .catch((error) => failureHandler(error, params));
