import Pidgin from '@lessonnine/pidgin.js';
import { LOCALE, isPrivateEnv } from 'src/dataLayer/env';

const importAll = (req) =>
  req.keys().reduce((acc, file) => {
    const locale = file.replace('./', '').replace('.json', '');
    return {
      ...acc,
      [locale]: req(file)
    };
  }, {});

const locales = importAll(
  require.context('src/viewLayer/assets/locales', false, /\.json$/)
);

const pidgin = new Pidgin({
  locales,
  onMissingKey: () => { if (isPrivateEnv()) return; }
});

const translations = () => {
  if (pidgin.locale !== LOCALE) {
    pidgin.configure({ locale: LOCALE });
  }
  return pidgin;
};

export const t = (key) =>
  translations().t(key);

export const namespaceT = (...args) =>
  translations().namespace(...args);
