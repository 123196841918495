import AwsApiClient from '@lessonnine/api-client.js';
import {
  APIGATEWAY_BASE_URL,
  AWS_SESSION_ENDPOINT,
  AWS_SESSION_METHOD
} from 'src/dataLayer/env';

const awsApiClient = new AwsApiClient({
  baseUrl: APIGATEWAY_BASE_URL,
  awsSessionEndpoint: AWS_SESSION_ENDPOINT,
  awsSessionMethod: AWS_SESSION_METHOD
});

export default awsApiClient;
