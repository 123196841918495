import styled from 'styled-components';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import { Box } from 'src/viewLayer/components/DesignSystem';

export const ImageContainer = styled(Box)`
  display: none;
  text-align: center;
  width: 10rem;

  @media only screen and ${devices.fromxxsmall} {
    display: inline-block;
  }

  @media only screen and ${devices.fromsmall} {
    display: none;
  }
`;
