import React, { useEffect } from 'react';

// hooks
import useTrackPageShown from 'src/viewLayer/components/hooks/useTrackPageShown';

// helpers
import requestSSOAuthToken from 'src/dataLayer/remoteData/babbelAPI/requestSSOAuthToken';
import { postMessage } from 'src/viewLayer/helpers/safeNativeReporter';
import { Layout, Spinner } from 'src/viewLayer/components/DesignSystem';

export default function SuccessCallbackPage({ appType, path }) {
  useTrackPageShown({ appType, pageType: 'b2b_sso_' + (path || 'success') });

  useEffect(() => {
    requestSSOAuthToken().then(({ authenticationToken }) => postMessage(authenticationToken));
  }, []);

  return (
    <Layout align="center" padding={[150, 0]}>
      <Spinner data-testid="babbel-spinner" size={42} />
    </Layout>
  );
}
