import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import useAsyncData from 'src/viewLayer/components/hooks/useAsyncData';
import requestAuthToken from 'src/dataLayer/remoteData/babbelAPI/requestAuthToken';

export default function useHandleAuthTokenRequest(props) {
  const { locale } = useRouteParamsContext();

  const {
    makeRequest,
    inProgress
  } = useAsyncData(() => requestAuthToken({ locale, ...props }));

  return {
    makeAuthTokenRequest: makeRequest,
    authTokenRequestInProgress: inProgress
  };
};
