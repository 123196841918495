import { SIGN_IN_URL } from 'src/dataLayer/env';
import safeLocalStorage from 'src/viewLayer/helpers/safeLocalStorage';
import { postMessage } from 'src/viewLayer/helpers/safeNativeReporter';

export default ({
  makeAuthTokenRequest,
  makeAuthSessionRequest,
  devicePath,
  redirectTo,
  setPasswordErrorKey,
  captchaResponseToken
}) => makeAuthTokenRequest(captchaResponseToken).then(
  (token) => {
    // on successful response in Mobile WebView
    if (devicePath === 'login-mobile') {
      return postMessage(token);
    }
    safeLocalStorage.remove('email');
    window.location.replace(`${SIGN_IN_URL}?return_to=${redirectTo}&auth_token=${token}`);
  },
  (errorKey) => {
    setPasswordErrorKey(errorKey);
    makeAuthSessionRequest();
  }
);
