import React from 'react';
import useTrackPageShown from 'src/viewLayer/components/hooks/useTrackPageShown';
import TrackableButton from 'src/viewLayer/components/shared/TrackableButton';
import HandCardImage from 'src/viewLayer/components/pages/Images/HandCard.svg';
import ScribbleImage from 'src/viewLayer/components/pages/Images/Scribble.svg';
import { useBusinessPagePath } from 'src/viewLayer/navigation';
import { namespaceT } from 'src/viewLayer/helpers/translations';
import { isRebranded, Stack, Box, useTheme } from 'src/viewLayer/components/DesignSystem';
import {
  BusinessErrorPageWithBackground,
  BusinessErrorPageLayout,
  BusinessErrorCard,
  BusinessErrorCardTitle,
  BusinessErrorCardMessage,
  BackgroundItemsContainer,
  BusinessErrorPageImage
} from './index.styled';

const BackgroundItems = !isRebranded && [HandCardImage, ScribbleImage];

export default function BusinessErrorPage({ appType }) {
  const theme = useTheme();

  useTrackPageShown({ appType,  pageType: 'b2b_sso_error' });

  const t = namespaceT('email.error.invalid_company_email');

  return (
    <BusinessErrorPageWithBackground>
      <BusinessErrorPageLayout
        alignItems="center"
        direction="stack"
        className="BusinessErrorPage"
        data-testid="business-error-page"
      >
        <BusinessErrorCard>
          <Box
            padding={{
              xxxsmall: [52, 20],
              xxsmall: [52, 20],
              xsmall: [55, 120],
              small: [55, 120]
            }}
          >
          <Stack gap={ isRebranded ? 124 : 24 }>
            <Stack gap={24} align="center" alignItems="center">
              { isRebranded && <BusinessErrorPageImage color={ theme.color.neutral.onSurfaceSubdued2 } /> }
              <BusinessErrorCardTitle>{ t('title') }</BusinessErrorCardTitle>
              <BusinessErrorCardMessage>{ t('body') }</BusinessErrorCardMessage>
            </Stack>
            <TrackableButton
              color={ isRebranded ? 'secondary-default' : 'primary' }
              dataTestid="sso-error-back-button"
              variant="secondary"
              title="Business error page CTA"
              trackingId="sso_error_back"
              href={ useBusinessPagePath() }
            >
              { t('cta') }
            </TrackableButton>
          </Stack>
          </Box>
        </BusinessErrorCard>
        <BackgroundItemsContainer>
          {BackgroundItems && BackgroundItems.map((BackgroundItem, index) => (
            <BackgroundItem key={index} />
          ))}
        </BackgroundItemsContainer>
      </BusinessErrorPageLayout>
    </BusinessErrorPageWithBackground>
  );
};
