import { useEffect } from 'react';
import { trackPerformance } from 'src/dataLayer/remoteData/babbelEventStream';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';

export default function usePerformanceTracker() {
  const { locale } = useRouteParamsContext();

  useEffect(() => {
    // We can't use the spread operator on performance.timing to get its values, since they come from its prototype
    // So we can't create a new object including `locale` and `learnLanguageCode` to pass to 'trackPerformance'
    const performanceData = Object.assign(performance.timing, { locale, learnLanguageCode: 'NA' });

    // setTimeout ensures trackPerformance moves to the top of the call stack so we don't wait for the request to finish
    const sendPerformanceTracking = () => setTimeout(() => trackPerformance(performanceData));

    // Wait for the load event if `loadEventEnd` is set to 0
    performanceData.loadEventEnd
      ? sendPerformanceTracking()
      : window.addEventListener('load', sendPerformanceTracking);
  }, [locale]);
};
