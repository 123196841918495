import React from 'react';

// Hooks
import usePerformanceTracker from 'src/viewLayer/components/hooks/usePerformanceTracker';

// Components
import EmailLoginFlow from '../flows/EmailLoginFlow';

export default function NativeMobileLoginPages(props) {
  usePerformanceTracker();

  return (
    <>
      <EmailLoginFlow {...props}/>
    </>
  );
}
