import {
  Link as InternalLink
} from '@lessonnine/design-system.lib';

import {
  Link as InternalLinkRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Link = {
  [DS_VERSIONS.default]: InternalLink,
  [DS_VERSIONS.rebranded]: InternalLinkRebranded
};

export default Link[CURRENT_DS_VERSION];
