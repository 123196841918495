import React from 'react';
import { Inline, Text } from 'src/viewLayer/components/DesignSystem';
import { t } from 'src/viewLayer/helpers/translations';
import TrackableLink from 'src/viewLayer/components/shared/TrackableLink';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';
import { useEmailPagePath } from 'src/viewLayer/navigation';
import safeLocalStorage from 'src/viewLayer/helpers/safeLocalStorage';

export default () => {
  const { devicePath } = useAppConfigContext();

  if (devicePath !== 'login') return null;

  return (
    <Inline align="center" alignItems="center" gap={3}>
      <Text fontSize="small">
        { t('password.links.change_email_question') }
      </Text>
      <TrackableLink
        data-testid="change-email-link"
        href={ useEmailPagePath() }
        onClick={ () => safeLocalStorage.remove('email') }
        trackingId={'change_email_link'}
        size="small"
        weight="strong"
      >
        { t('password.links.change_email_action') }
      </TrackableLink>
    </Inline>
  );
};
