import React from 'react';
import declareContext from './declareContext';

const extractProps = ({ devicePath }) => ({ devicePath });

const AppConfigContext = declareContext(extractProps);

export const useAppConfigContext = AppConfigContext.useContext;
export const AppConfigProvider = ({ children, ...props }) => {
  return (
    <AppConfigContext.Provider { ...props }>
      {children}
    </AppConfigContext.Provider>
  );
};
