import { APIGATEWAY_BASE_URL } from 'src/dataLayer/env';
import makeAjaxRequest from './../makeAjaxRequest';

export const ERROR_KEYS = {
  422: {
    key: 'email.error.invalid_email_format'
  },
  401: {
    key: 'email.error.expired_token'
  },
  400: {
    key: 'common.error.unknown'
  },
  default: {
    key: 'common.error.unknown'
  }
};

export default ({
  locale,
  email
}) => makeAjaxRequest({
  verb: 'POST',
  payload: { session: { email } },
  url: `${APIGATEWAY_BASE_URL}/v1.0.0/${locale}/authentication/session`,
  errorKeys: ERROR_KEYS
}).then(({
  session: {
    code,
    captcha
  }
}) => ({
  sessionCode: code,
  captchaConfig: Object.keys(captcha).length === 0 ? null : captcha
}));
