import styled from 'styled-components';
import { Box } from 'src/viewLayer/components/DesignSystem';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import zIndex from 'src/viewLayer/styling/mixins/z-index';

export const HandCardContainer = styled(Box)`
  left: -36%;
  top: 15rem;
  z-index: ${zIndex.decorationFront};
`;

export const ScribbleContainer = styled(Box)`
  right: -32%;
  top: -5rem;
  z-index: ${zIndex.decorationBack};

  @media only screen and ${devices.fromsmall} {
    right: -36%;
  }

  @media only screen and ${devices.frommedium} {
    right: -48%;
  }
`;
