import declareEvent from './declareEvent';
import { handleInvalidEventProps } from 'src/dataLayer/remoteData/babbelEventStream/validateEventProps';

const PAGE_SHOWN_EVENT_NAME = 'authentication_page:shown';
const PAGE_SHOWN_EVENT_VERSION = 3;

export const trackGuiInteracted = declareEvent({
  name: 'gui:interacted',
  version: 2,
  getCalltimePayload: ({ guiElementName }) => ({ gui_element: guiElementName })
});

export const trackPageShown = declareEvent({
  name: PAGE_SHOWN_EVENT_NAME,
  version: PAGE_SHOWN_EVENT_VERSION,
  getCalltimePayload: ({ appType, pageType }) => {
    if (!appType || !pageType) {
      handleInvalidEventProps({
        name: PAGE_SHOWN_EVENT_NAME,
        version: PAGE_SHOWN_EVENT_VERSION,
        appType,
        pageType
      });
    }
    return { page_name: `${appType}:${pageType}` };
  }
});


export const trackPerformance = declareEvent({
  name: 'performance:page_loaded',
  version: 2,
  getCalltimePayload: ({
    connectEnd,
    connectStart,
    domainLookupEnd,
    domainLookupStart,
    domComplete,
    domContentLoadedEventEnd,
    domContentLoadedEventStart,
    domInteractive,
    domLoading,
    fetchStart,
    loadEventEnd,
    loadEventStart,
    navigationStart,
    redirectEnd,
    redirectStart,
    responseEnd,
    responseStart,
    requestStart,
    secureConnectionStart,
    unloadEventEnd,
    unloadEventStart
  }) => ({
    connect_end: connectEnd,
    connect_start: connectStart,
    domain_lookup_end: domainLookupEnd,
    domain_lookup_start: domainLookupStart,
    dom_complete: domComplete,
    dom_content_loaded_event_end: domContentLoadedEventEnd,
    dom_content_loaded_event_start: domContentLoadedEventStart,
    dom_interactive: domInteractive,
    dom_loading: domLoading,
    fetch_start: fetchStart,
    load_event_end: loadEventEnd,
    load_event_start: loadEventStart,
    navigation_start: navigationStart,
    redirect_end: redirectEnd,
    redirect_start: redirectStart,
    response_end: responseEnd,
    response_start: responseStart,
    request_start: requestStart,
    secure_connection_start: secureConnectionStart,
    unload_event_end: unloadEventEnd,
    unload_event_start: unloadEventStart
  })
});
