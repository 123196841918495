import {
  Box as InternalBox,
  Inline as InternalInline,
  Layout as InternalLayout,
  Stack as InternalStack
} from '@lessonnine/design-system.lib';

import {
  Box as InternalBoxRebranded,
  Inline as InternalInlineRebranded,
  Layout as InternalLayoutRebranded,
  Stack as InternalStackRebranded
} from '@lessonnine/design-system-rebranded.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const LayoutMap = {
  [DS_VERSIONS.default]: {
    Box: InternalBox,
    Inline: InternalInline,
    Layout: InternalLayout,
    Stack: InternalStack
  },
  [DS_VERSIONS.rebranded]: {
    Box: InternalBoxRebranded,
    Inline: InternalInlineRebranded,
    Layout: InternalLayoutRebranded,
    Stack: InternalStackRebranded
  }
};

export default LayoutMap[CURRENT_DS_VERSION];
