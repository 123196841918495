import { getTrackingUUID } from 'src/dataLayer/localData/cookies';
import {
  BABBEL_APP_VERSION,
  ROLLBAR_ACCESS_TOKEN,
  ROLLBAR_API_URL,
  RUNTIME_ENVIRONMENT
} from 'src/dataLayer/env';

const pickStatus = ({ status }) => ({ status });

const declareReporter = (level) => (body, rawPayload) => {
  if (rawPayload.body && rawPayload.body.token && rawPayload.body.token.password) {
    delete rawPayload.body.token.password;
    delete rawPayload.body.token.email;
  }
  const payload = { ...rawPayload, trackingUuid: getTrackingUUID() };

  return window.fetch(ROLLBAR_API_URL, {
    method: 'POST',
    body: JSON.stringify({
      access_token: ROLLBAR_ACCESS_TOKEN,
      data: {
        body: {
          message: {
            body, payload
          }
        },
        client: {
          javascript: {
            browser: navigator.userAgent
          }
        },
        // TODO: remove BABBEL_APP_VERSION, it is not available in CI
        code_version: BABBEL_APP_VERSION,
        environment: RUNTIME_ENVIRONMENT,
        level,
        // TODO: We need to change the platform value here
        // when we handle WebView. value should depend on
        // route, if /login-mobile/ then "mobile", else "browser"
        platform: 'browser',
        request: {
          url: window.location.href,
          query_string: window.location.search
        }
      }
    })
  }).then(pickStatus, pickStatus);
};

export const reportError = declareReporter('error');
