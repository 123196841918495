/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';

// Form submission
import onEmailSubmit from './onEmailSubmit';
import onPasswordSubmit from './onPasswordSubmit';

// Components
import LoginEmailPage from '../../pages/LoginEmailPage';
import LoginPasswordPage from '../../pages/LoginPasswordPage';
import CaptchaFeature from '../../shared/CaptchaFeature';
import DefaultLoginRedirect from 'src/viewLayer/components/redirects/DefaultLoginRedirect';

// Hooks
import useHandleSessionRequest from './useHandleSessionRequest';
import useInputState from '../../hooks/useInputState';
import useHandleAuthTokenRequest from './useHandleAuthTokenRequest';
import { usePasswordPagePath } from '../../../navigation';
import { useRouteParamsContext } from 'src/viewLayer/components/contexts/RouteParamsContext';
import { useAppConfigContext } from 'src/viewLayer/components/contexts/AppConfigContext';
import { extractCaptchaKey } from 'src/viewLayer/components/shared/CaptchaFeature/helpers';
import { NO_CAPTCHA_REQUIRED_STATUS } from 'src/dataLayer/localData/captcha.js';

const EMAIL_ROUTE_PATH = 'email';
const PASSWORD_ROUTE_PATH = 'password';

/*
 * Parent component of the login sub-pages, carries out the login operations.
 */
export default function EmailLoginFlow(props) {
  const navigate = useNavigate();
  const passwordPagePath = usePasswordPagePath();
  const { redirectTo } = useRouteParamsContext();
  const { devicePath } = useAppConfigContext();

  const initEmail = {
    errorKey: '',
    inputValue: props.localStorageEmail || email || '',
    isFocused: true
  };

  // State
  const {
    inputValue: email,
    errorKey: emailErrorKey,
    isFocused: emailIsFocused,
    setInputValue: setEmail,
    setErrorKey: setEmailErrorKey,
    resetInputState: resetEmailState
  } = useInputState(initEmail);

  const {
    inputValue: password,
    errorKey: passwordErrorKey,
    isFocused: passwordIsFocused,
    setInputValue: setPassword,
    setErrorKey: setPasswordErrorKey,
    resetInputState: resetPasswordState
  } = useInputState();

  // Endpoints
  const {
    makeAuthSessionRequest,
    authSessionRequestInProgress,
    sessionCode,
    captchaConfig
  } = useHandleSessionRequest(email);

  const getInitialCaptchaResponseTokenValue = useCallback(
    () => extractCaptchaKey(captchaConfig) ? undefined : NO_CAPTCHA_REQUIRED_STATUS,
    [captchaConfig]
  );

  const [captchaResponseToken, setCaptchaResponseToken] = useState(getInitialCaptchaResponseTokenValue());

  useEffect(() => {
    setCaptchaResponseToken(getInitialCaptchaResponseTokenValue());
  }, [sessionCode, getInitialCaptchaResponseTokenValue]);

  const {
    makeAuthTokenRequest,
    authTokenRequestInProgress
  } = useHandleAuthTokenRequest({ sessionCode, email, password, captchaResponseToken });

  // Form submission props
  const emailSubmitParams = {
    email,
    makeAuthSessionRequest,
    setEmailErrorKey,
    resetPasswordState,
    passwordPagePath
  };

  const passwordSubmitParams = {
    makeAuthTokenRequest,
    makeAuthSessionRequest,
    devicePath,
    redirectTo,
    setPasswordErrorKey,
    captchaResponseToken
  };

  // JSX
  // invisible captcha needs to know if there was an error with the password, so that we can manually trigger it again
  const captchaFeature = (
    <CaptchaFeature
      passwordErrorKey={ passwordErrorKey }
      captchaConfig={ captchaConfig }
      setCaptchaResponseToken={ setCaptchaResponseToken }
      sessionCode={ sessionCode }
      captchaResponseToken={ captchaResponseToken}
    />
  );

  return (
    <Routes>
      <Route
        path={ EMAIL_ROUTE_PATH }
        element={
          <LoginEmailPage
            appType={props.appType}
            email={email}
            errorKey={emailErrorKey}
            isFocused={emailIsFocused}
            path={ EMAIL_ROUTE_PATH }
            inProgress={authSessionRequestInProgress}
            onChange={setEmail}
            onReset={resetEmailState}
            onSubmit={ (event) => {
              event.preventDefault();
              event.stopPropagation();
              onEmailSubmit(emailSubmitParams, navigate);
            }}
          />
        }
      />
      <Route
        path={ PASSWORD_ROUTE_PATH }
        element={
          <LoginPasswordPage
            appType={props.appType}
            password={password}
            errorKey={passwordErrorKey}
            isFocused={passwordIsFocused}
            path={ PASSWORD_ROUTE_PATH }
            email={email}
            captchaFeature={captchaFeature}
            captchaResponseToken={captchaResponseToken}
            inProgress={authTokenRequestInProgress}
            onChange={ setPassword }
            onSubmit={ (event) => {
              event.preventDefault();
              event.stopPropagation();
              onPasswordSubmit(passwordSubmitParams);
            }}
          />
        }
      />
      <Route
        path="*"
        element={ <DefaultLoginRedirect /> }
      />
    </Routes>
  );
};
