import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';
import coloursDefault from './coloursDefault';
import coloursRebranding from './coloursRebranding';

export const getColoursFromTheme = (theme) => {
  const coloursMap = {
      [DS_VERSIONS.default]: coloursDefault,
      [DS_VERSIONS.rebranded]: coloursRebranding
  };
  return coloursMap[CURRENT_DS_VERSION](theme);
};
