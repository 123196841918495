/* eslint-disable max-lines-per-function */
import React from 'react';
import { useNavigate } from 'react-router-dom';
// context and data
import useTrackPageShown from 'src/viewLayer/components/hooks/useTrackPageShown';
import useInputState from '../../hooks/useInputState';
// view components
import { isRebranded, Box, Inline, Text, Stack } from 'src/viewLayer/components/DesignSystem';
import IdentityProviderInput from 'src/viewLayer/components/shared/Inputs/IdentityProviderInput';
import BackButton from 'src/viewLayer/components/pages/BackButton';
import TrackableButton from 'src/viewLayer/components/shared/TrackableButton';
import DataProcessingSection from './DataProcessingSection';
import { t } from 'src/viewLayer/helpers/translations';
import useB2BSessionRequest from 'src/viewLayer/components/hooks/useB2BSessionRequest';
import { useBusinessErrorPagePath } from 'src/viewLayer/navigation';
import { ResponsiveHeader } from 'src/viewLayer/components/shared/ResponsiveHeader';
import { Card, CardLayout, FormContainer, LoginBusinessPageWithBackground } from './index.styled';
import validateAndFormatIdentityProvider from './validateAndFormatIdentityProvider';

export default function LoginBusinessPage({ children, appType }) {
  useTrackPageShown({ appType,  pageType: 'b2b_sso_subdomain' });
  const navigate = useNavigate();
  const redirectPath = useBusinessErrorPagePath();

  const initIdentityProvider = {
    inputValue: '',
    isFocused: true
  };
  const {
    errorKey: identityProviderErrorKey,
    setErrorKey: setIdentityProviderErrorKey,
    inputValue: identityProvider,
    isFocused: identityProviderIsFocused,
    setInputValue: setIdentityProvider,
    resetInputState: resetIdentityProviderState
  } = useInputState(initIdentityProvider);

  const {
    makeIdentityProviderValidationRequest,
    identityProviderValidationRequestInProgress,
    isRedirecting
  } = useB2BSessionRequest(identityProvider);

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await makeIdentityProviderValidationRequest();
    } catch (errorKey) {
      errorKey === 'redirectToErrorPage' ?
        navigate(redirectPath, { replace: true }) :
        setIdentityProviderErrorKey(errorKey);
    }
  };

  const largeSizeHeader = isRebranded ? 'h1' : 'h2';

  return (
    <LoginBusinessPageWithBackground>
      <CardLayout align="center">
        <Card
          data-testid="login-business-page"
          padding={{
            xxxsmall: [40, 16, 37, 16],
            xsmall: isRebranded ? [40, 0, 37, 0] : [40, 120, 37, 120],
            small: isRebranded ? [77, 0, 28, 0] : [77, 100, 28, 100]
          }}
        >
          <BackButton trackingId="backbutton-business-to-email" path="business" />
          <Inline padding={{ xxsmall: [26, 0, 40], small: [0, 0, 40] }} align="center">
            <ResponsiveHeader largeSize={largeSizeHeader} isCentered={false}>{ t('email.title') }</ResponsiveHeader>
          </Inline>
          <Stack gap={{ xxxsmall: isRebranded ? 47 : 16, small: isRebranded ? 60 : 16 }}>
            <FormContainer>
              <form onSubmit={ onSubmit } data-testid="login-business-page-form">
                {
                  !isRebranded &&
                    <Box padding={[0, 0, 12, 0]}>
                      <Text fontWeight={500} letterSpacing={0.5}>{ t('login.b2b.company_identity_provider') }</Text>
                    </Box>
                }
                <IdentityProviderInput
                  clearValue={ resetIdentityProviderState }
                  errorKey={ identityProviderErrorKey }
                  isFocused={ identityProviderIsFocused }
                  label={ isRebranded && t('login.b2b.company_identity_provider') }
                  onChangeHandler={ (event) => setIdentityProvider(validateAndFormatIdentityProvider(event)) }
                  value={ identityProvider }
                />
              </form>
              <Box padding={[27, 0, 0, 0]}>
                <TrackableButton
                  id="uat-continue-button"
                  dataTestid="login-business-page-submit-button"
                  disabled={
                    identityProvider.length === 0 || identityProviderValidationRequestInProgress || isRedirecting
                  }
                  title="Continue button"
                  trackingId="identity_provider_continue"
                  inProgress={ identityProviderValidationRequestInProgress || isRedirecting }
                  fullWidth={true}
                  variant="primary"
                  onClick={ onSubmit }
                >
                  { t('email.button.continue') }
                </TrackableButton>
              </Box>
            </FormContainer>
            <DataProcessingSection />
          </Stack>
        </Card>
      </CardLayout>
      { children }
    </LoginBusinessPageWithBackground>
  );
};
