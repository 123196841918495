import { ERROR_KEYS } from 'src/dataLayer/remoteData/babbelAPI/requestAuthSessionParams';
import { getErrorText } from 'src/dataLayer/remoteData/babbelAPI/makeAjaxRequest/responseHandlers';
import isEmailValid from './isEmailValid';

const onValidEmailSubmit = (params, navigate) =>
  params.makeAuthSessionRequest()
    .then(
      () => {
        const { resetPasswordState, passwordPagePath } = params;
        resetPasswordState();
        navigate(passwordPagePath);
      },
      (errorKey) => params.setEmailErrorKey(errorKey)
    );

const onInvalidEmailSubmit = ({ setEmailErrorKey }) => {
  const triggeredError = ERROR_KEYS['422'];
  setEmailErrorKey(getErrorText(triggeredError));
};

export default (params, navigate) =>
  isEmailValid(params.email) ?
    onValidEmailSubmit(params, navigate) :
    onInvalidEmailSubmit(params);
