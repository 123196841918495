import React from 'react';
import safeLocalStorage from 'src/viewLayer/helpers/safeLocalStorage';

// Hooks
import usePerformanceTracker from 'src/viewLayer/components/hooks/usePerformanceTracker';

// Components
import NavBar from './NavBar';
import EmailLoginFlow from '../flows/EmailLoginFlow';

export default function LoginPages(props) {
  usePerformanceTracker();
  const localStorageEmail = JSON.parse(safeLocalStorage.get('userInput'))?.email;

  return (
    <>
      <NavBar/>
      <EmailLoginFlow appType={ props.appType } localStorageEmail={ localStorageEmail } />
    </>
  );
}
