import React from 'react';
import { Footer } from 'src/viewLayer/components/DesignSystem';
import { LOCALE } from 'src/dataLayer/env';
import { trackGuiInteracted } from 'src/dataLayer/remoteData/babbelEventStream';
import { FooterContainer } from './index.styled';

const footerContent = require(`src/viewLayer/assets/translatedFooterContent/${LOCALE}.json`);

const handleFooterLinkClick = (footerLinkId) => {
  trackGuiInteracted({ guiElementName: footerLinkId });
};

export default () => {
  return (
    <FooterContainer>
      <Footer color="transparent">
        {Object.entries(footerContent).map(([sectionName, sectionLinks]) => (
          <Footer.Section sectionName={sectionName} key={sectionName}>
            { sectionLinks.map((sectionLinkData) =>
              <Footer.Item key={ sectionLinkData.id }>
                <Footer.Link
                  { ...sectionLinkData }
                  onClick={ handleFooterLinkClick }
                  sectionName={ sectionName }
                >
                  { sectionLinkData.title }
                </Footer.Link>
              </Footer.Item>
            )}
          </Footer.Section>
        ))}
      </Footer>
    </FooterContainer>
  );
};
