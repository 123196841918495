import { useEffect, useState } from 'react';

export default () => {
  const [viewport, setViewport] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setViewport(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return viewport;
};
