import useAsyncDataState from './useAsyncDataState';

export default (asyncFn) => {
  const {
    state,
    setState: {
      onAttempt,
      onSuccess,
      onFailure
    }
  } = useAsyncDataState();

  const makeRequest = (...args) => {
    onAttempt();
    return asyncFn(...args).then((response) => {
      onSuccess(response);
      return response;
    }, (error) => {
      onFailure(error);
      return Promise.reject(error);
    });
  };

  return {
    ...state,
    makeRequest
  };
};
