/* eslint-disable no-restricted-imports */
import babbelTracker from '@lessonnine/tracker.js';
import { UUID, TRACKING_URL } from 'src/dataLayer/env';

const APP_NAME = 'authentication.spa';
const TRACKING_UUID_NAME = 'babbeltrackinguuid';

const tracker = new babbelTracker.Tracker({
  url: TRACKING_URL,
  trackingUuidName: TRACKING_UUID_NAME,
  userUuid: UUID,
  defaultPayload: {
    origin: APP_NAME,
    provider: APP_NAME
  }
});

export default tracker.track;
