import makeAjaxRequest from 'src/dataLayer/remoteData/babbelAPI/makeAjaxRequest';
import { APIGATEWAY_BASE_URL, DASHBOARD_URL } from 'src/dataLayer/env';
import { isValidBabbelReturnUrl } from 'src/dataLayer/localData/queryParams';

export const ERROR_KEYS = {
  422: {
    key: 'login.b2b.error.invalid_identity_provider'
  },
  default: {
    key: 'redirectToErrorPage'
  }
};

export const validateIdentityProviderAndRedirect = async (
  identityProvider,
  locale,
  returnTo
) => {
  const data = await makeAjaxRequest({
    verb: 'PUT',
    payload: {
      sso_session: {
        protocol: 'saml',
        idp: identityProvider
      }
    },
    url: `${ APIGATEWAY_BASE_URL }/v1.0.0/${ locale }/authentication/sso_session`,
    errorKeys: ERROR_KEYS
  });

  if (data?.sso_session?.url) {
    const url = new URL(data.sso_session.url);
    const validatedReturnTo = isValidBabbelReturnUrl(returnTo) ? returnTo : DASHBOARD_URL;
    url.searchParams.append('return_to', validatedReturnTo);
    window.location.assign(url.href);
    // We need this to keep the button disabled while redirecting
    return { isRedirecting: true };
  }
};
