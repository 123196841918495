import React from 'react';
import { isRebranded, Text } from 'src/viewLayer/components/DesignSystem';

import { InterpolatedLink } from './index.styled';

const InterpolateLink = ({ translation, firstLink, secondLink }) => {
  const firstSplitter = `%{${firstLink.name}}`;
  const secondSplitter = `%{${secondLink.name}}`;

  const [part1, rest] = translation.split(firstSplitter);
  const [part2, part3] = rest.split(secondSplitter);

  return (
    <Text
      fontSize={{ small: isRebranded ? 'small' : 'xsmall' }}
      letterSpacing={ !isRebranded && 0.5}
    >
      <span>{part1}</span>
      <InterpolatedLink
        href={firstLink.path}
        size={ isRebranded ? 'small' : 'xsmall' }
        target="_blank"
        weight="strong"
        underlined={ false }
      >
        {firstLink.translation}
      </InterpolatedLink>
      <span>{part2}</span>
      <InterpolatedLink
        href={secondLink.path}
        target="_blank"
        size={ isRebranded ? 'small' : 'xsmall' }
        weight="strong"
        underlined={ false }
      >
        {secondLink.translation}
      </InterpolatedLink>
      <span>{part3}</span>
    </Text>
  );
};

export default InterpolateLink;
