import {
  CAPTCHA_PROVIDER_CONFIG,
  INVISIBLE_CAPTCHA_TYPE,
  NO_CAPTCHA_REQUIRED_STATUS
} from 'src/dataLayer/localData/captcha';

export const isInvisible = (provider) => CAPTCHA_PROVIDER_CONFIG[provider]?.type === INVISIBLE_CAPTCHA_TYPE;

export const executeCaptcha = (provider, captchaWidgetID) =>
  window[CAPTCHA_PROVIDER_CONFIG[provider].windowMethod].execute(captchaWidgetID);

export const extractCaptchaKey = (captchaConfig) =>
  captchaConfig ? captchaConfig.params.site_key : null;

export const resetCaptcha = (provider) =>
  window[CAPTCHA_PROVIDER_CONFIG[provider].windowMethod].reset();

const isCaptchaRequired = (captchaResponseToken) => !(captchaResponseToken === NO_CAPTCHA_REQUIRED_STATUS);

export const formatCaptchaResult = (captchaResponseToken) => {
  if (!captchaResponseToken) throw new Error('captchaResponseToken was missing');
  return (isCaptchaRequired(captchaResponseToken))
    ? ({ response: captchaResponseToken })
    : {};
};
